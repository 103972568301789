import { createStyles } from '@mui/styles';

import { WessistTheme } from '@common/style/themeSelector';

export const styles = (theme: WessistTheme) =>
  createStyles({
    div_first_root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px',
    },
    div_first: {
      width: '100%',
      maxWidth: '517px',
      height: '510px',
      backgroundColor: '#252525',
      border: '1px solid #8d8d8d',
      borderRadius: '20px',
      padding: '12px',
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'center',
    },
    prev_wrap: {
      margin: '0px',
      width: '100%',
      height: '32px',
    },
    title_box: {
      width: '100%',
      height: '40px',
      flexDirection: 'row',
      justifyContent: 'start',
      alignItems: 'center',
      margin: '12px 0px 0px 12px',
    },
    title: {
      fontSize: '36px',
      fontWeight: '700',
      color: theme.Wcolor.white,
    },
    text_box: {
      width: `calc(100% - 24px)`,
      height: '84px',
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'center',
      margin: '26px 0px 0px 12px',
      borderBottom: '1px solid #8d8d8d',
    },
    text_wrap: {
      width: `calc(100%)`,
      height: '27px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      gap: '8px',
      alignItems: 'center',
      marginBottom: '15px',
    },
    text: {
      fontSize: '20px',
      color: theme.Wcolor.white,
    },
    info_box: {
      width: `calc(100%)`,
      height: '27px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',

    },
    info: {
      fontSize: '16px',
      color: theme.Wcolor.white,
      lineHeight: '1.1',
    },
    emailbutton: {
      maxWidth: '150px',
      height: '25px',
      fontSize: '16px',
      color: theme.Wcolor.black,
      backgroundColor: theme.Wcolor.gray2,
      borderRadius: '4px',
    },
    upgradebutton: {
      maxWidth: '150px',
      height: '25px',
      fontSize: '16px',
      color: theme.Wcolor.white,
      backgroundColor: theme.Wcolor.pink,
      borderRadius: '4px',
    },
    status: {
      fontSize: '14px',
      fontWeight: 'bold',
    },
    enabled: {
      color: 'green', // Enabled 상태일 때 초록색
    },
    disabled: {
      color: 'red', // Disabled 상태일 때 빨간색
    },
    toggleButton: {
      width: '60px',
      // height: '24px',
      color: theme.Wcolor.white,
      marginRight: '0px',
      '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
          color: '#fff',
          '& + .MuiSwitch-track': {
            backgroundColor: '#D84E98',
            opacity: 1,
          },
        },
      },
      '& .MuiSwitch-track': {
        backgroundColor: '#8D8D8D',
      },
    },
    button_box: {
      width: '100%',
      height: '40px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '12px 0px 0px 12px',
    },
    button: {
      maxWidth: '150px',
      height: '40px',
      fontSize: '20px',
      color: theme.Wcolor.white,
    },
  });
