import { createStyles } from '@mui/styles';

import { WessistTheme } from '@common/style/themeSelector';

export const styles = (theme: WessistTheme) =>
  createStyles({
    div_root: {
      width: '100%',
      // width: '100vw',        // 전체 화면 너비
      overflowX: 'auto',
      padding: '0px 0px 0px 32px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      '&::-webkit-scrollbar': {
        height: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888', // 스크롤바 색상
        borderRadius: '4px', // 스크롤바 둥글게
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#555', // 마우스 오버 시 색상 변경
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: theme.Wcolor.black, // 스크롤바 트랙 배경 색상
      },
      flexShrink: 1,
    },
    div_image_item_wrap: {
      display: 'flex',
      flexDirection: 'row',
      marginRight: '16px',
      justifyContent: 'space-between',
      flexShrink: 0,
    },
    div_image_img: {
      backgroundColor: theme.Wcolor.lightpink,
      border: '1px solid #D84E98',
      borderRadius: '12px',
      overflow: 'hidden',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    div_image_but: {
      width: '20px',
      display: 'flex',
      flexDirection: 'column',
    },
    div_image_wrap: {
      width: '20px',
      height: '30px',
    },
  });
