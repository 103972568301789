import { createStyles } from '@mui/styles';

import { WessistTheme } from '@common/style/themeSelector';

export const styles = (theme: WessistTheme) =>
  createStyles({
    modalOverlay: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
    modalContent: {
      width: '400px',
      height: '180px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 8,
      backgroundColor: theme.Wcolor.black,
    },
    modalTitle: {
      fontSize: '36px',
      color: theme.Wcolor.white,
      marginBottom: '8px',
    },
    modalText: {
      fontSize: '24px',
      color: theme.Wcolor.white,
      marginBottom: '8px',
    },
    buttonBox: {
      width: '100%',
      height: '50px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modalButton: {
      padding: '0px 24px',
      width: '150px',
      height: '40px',
      fontSize: '18px',
      backgroundColor: theme.Wcolor.gray1,
      borderRadius: 8,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.Wcolor.white,
      marginRight: '8px',
    },
    modalDelButton: {
      padding: '0px 24px',
      width: '150px',
      height: '40px',
      fontSize: '18px',
      backgroundColor: theme.Wcolor.pink2,
      borderRadius: 8,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.Wcolor.white,
      marginRight: '8px',
    },
  });
