import { createStyles } from '@mui/styles';

import { WessistTheme } from '@common/style/themeSelector';

export const styles = (theme: WessistTheme) =>
  createStyles({
    modalOverlay: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
    modalContent: {
      margin: '0px 50px',
      width: '100%',
      maxWidth: '484px',
      height: '279px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'center',
      borderRadius: 8,
      backgroundColor: theme.Wcolor.black,
    },
    closebox: {
      margin: '20px 30px 0px 0px',
      width: '100%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'end',
      alignItems: 'start',
    },
    imgwrap: {
      width: 'auto',
      height: 'auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'end',
      alignItems: 'start',
    },
    modalTitle: {
      fontSize: '36px',
      color: theme.Wcolor.white,
      marginBottom: '0px',
    },
    buttonBox: {
      width: '100%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'start',
    },
    title_patreon_button: {
      margin: '15px 10px 0px 10px',
      width: '100%',
      maxWidth: '350px',
      height: '52px',
      fontSize: '18px',
      // fontWeight: '700',
      color: theme.Wcolor.black,
      backgroundColor: theme.Wcolor.white,
      borderRadius: '12px',
    },
  });
