import { createStyles } from '@mui/styles';

import { WessistTheme } from '@common/style/themeSelector';

export const styles = (theme: WessistTheme) =>
  createStyles({
    div_first_root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'center',
      padding: '20px',
    },
    div_title: {
      width: `calc(100% - 40px)`,
      maxWidth: '1300px',
      height: '110px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '20px 20px',
      borderBottom: '1px solid #8d8d8d',
    },
    title_left: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'start',
    },
    title_title: {
      fontSize: '36px',
      fontWeight: '700',
      color: theme.Wcolor.white,
      marginBottom: '10px',
    },
    title_plan_wrap: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      alignItems: 'start',
    },
    title_state: {
      fontSize: '24px',
      // fontWeight: '700',
      color: theme.Wcolor.pink,
      marginRight: '5px',
    },
    title_due: {
      fontSize: '24px',
      // fontWeight: '700',
      color: theme.Wcolor.white,
    },
    title_right: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'end',
    },
    title_patreon_button: {
      width: '200px',
      height: '42px',
      fontSize: '18px',
      // fontWeight: '700',
      color: theme.Wcolor.black,
      backgroundColor: theme.Wcolor.white,
      borderRadius: '100px',
    },
    title_right_text: {
      fontSize: '22px',
      fontWeight: '500',
      color: theme.Wcolor.white,
      marginBottom: '10px',
    },
    div_first_wrap: {
      width: '100%',
      height: `calc(100% - 150px)`,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '32px',
      overflowX: 'auto', // 가로 스크롤 활성화
      boxSizing: 'border-box', // padding 포함 크기 계산
      '&::-webkit-scrollbar': {
        width: '8px', // 스크롤바 너비
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888', // 스크롤바 색상
        borderRadius: '4px', // 스크롤바 둥글게
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#555', // 마우스 오버 시 색상 변경
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: theme.Wcolor.black, // 스크롤바 트랙 배경 색상
      },
    },
    div_first: {
      width: '350px',
      height: '100%',
      maxHeight: '510px',
      display: 'flex',
      backgroundColor: '#252525',
      border: '1px solid #8d8d8d',
      borderRadius: '20px',
      padding: '12px',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexShrink: 0,
    },
    div_first_pink: {
      border: '1px solid #D84E98',
    },
    div_first_top: {
      width: '100%',
      height: 'auto',
      maxHeight: '510px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'center',
      flexShrink: 0,
    },
    div_first_title: {
      width: `calc(100% - 40px)`,
      height: '110px',
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'center',
      margin: '20px 20px 0px 20px ',
      borderBottom: '1px solid #8d8d8d',
    },
    title_first_title: {
      fontSize: '32px',
      fontWeight: '700',
      color: theme.Wcolor.white,
      marginBottom: '10px',
    },
    title_first_state: {
      fontSize: '24px',
      // fontWeight: '700',
      color: theme.Wcolor.white,
    },
    info_line: {
      width: '100%',
      height: '24px',
      fontSize: '20px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      alignItems: 'center',
      color: theme.Wcolor.white,
      margin: '12px 0px 0px 24px',
    },
    info: {
      fontSize: '16px',
      color: theme.Wcolor.white,
      lineHeight: '1.1',
    },
    basic_button: {
      width: '100%',
      height: '50px',
      fontSize: '24px',
      fontWeight: '700',
      color: theme.Wcolor.black,
      backgroundColor: theme.Wcolor.gray2,
      borderRadius: '100px',
    },
    utilmate_button: {
      backgroundColor: theme.Wcolor.pink,
    },
    ultimate_title_wrap: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      // border: '1px solid #8d8d8d',
    },
    utlimate_desc: {
      width: '89px',
      height: '29px',
      borderRadius: '20px',
      backgroundColor: theme.Wcolor.pink,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '10px',
    },
    utlimate_desc_txt: {
      fontSize: '14px',
      fontWeight: '700',
      color: theme.Wcolor.white,

    }
  });
