import { createStyles } from '@mui/styles';

import { WessistTheme } from '@common/style/themeSelector';

export const styles = (theme: WessistTheme) =>
  createStyles({
    root: {
      height: `calc(100% - 0px)`,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: '20px',
      // borderLeft: '1px solid #ddd',
      overflowY: 'auto',
    },
    title: {
      color: "#ffffff", // 글자색 (흰색)
      fontSize: '20px',   // 글자 크기
    },
    first_div: {
      height: 'auto',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
    },
    imggenTextFieldWrap: {
      overflowY: 'auto',
      width: '45%',
    },
    imggenTextField: {
      height: '100%',
      width: '100%',
      // border: '1px solid #ddd', // 기본 테두리
      // borderRadius: "4px",
      "& .MuiInputBase-input": {
        color: "#ffffff", // 글자색 (흰색)
        fontSize: '16px',   // 글자 크기
        lineHeight: '1.0',  // 줄간격
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          border: '1px solid #ddd', // 기본 테두리 색상
          borderRadius: "4px",
        },
        "&:hover fieldset": {
          border: '1px solid #ddd', // 호버 시 테두리 색상
          borderRadius: "4px",
        },
        "&.Mui-focused fieldset": {
          border: '1px solid #ddd', // 포커스 시 테두리 색상을 투명으로 설정
          borderRadius: "4px",
        },
      },
    },
    imggenButton: {
      margin: '0px 5px',
      height: '50px',
      width: '100px',
      color: theme.Wcolor.white,
      backgroundColor: theme.Wcolor.gray3,
    },
    imggenBox: {
      height: 'auto',
      width: `calc(55% - 100px)`,
      border: '1px solid #ddd',
    },
    second_div: {
      height: 'auto',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
    },
    last_div: {
      height: 'auto',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
    },
    imggenTextFieldWrapElement: {
      display: 'flex',
      flexDirection: 'row',
    },
    promptTextField: {
      height: `calc(50% - 20px)`,
      width: '100%',
      // border: '1px solid #ddd', // 기본 테두리
      // borderRadius: "4px",
      "& .MuiInputBase-input": {
        color: "#ffffff", // 글자색 (흰색)
        fontSize: '16px',   // 글자 크기
        lineHeight: '1.0',  // 줄간격
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          border: '1px solid #ddd', // 기본 테두리 색상
          borderRadius: "4px",
        },
        "&:hover fieldset": {
          border: '1px solid #ddd', // 호버 시 테두리 색상
          borderRadius: "4px",
        },
        "&.Mui-focused fieldset": {
          border: '1px solid #ddd', // 포커스 시 테두리 색상을 투명으로 설정
          borderRadius: "4px",
        },
      },
    },
    subtitle: {
      width: '50px',
      margin: '0px',
      padding: '0px',
      height: '20px',
      color: "#ffffff", // 글자색 (흰색)
      fontSize: '18px',   // 글자 크기
    },
    msg_response: {
      height: '100%',
      width: '100%',
      color: theme.Wcolor.white,
      fontSize: '16px',   // 글자 크기
      lineHeight: '1.0',  // 줄간격
    },
  });
