import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { Box, Button, Typography, CircularProgress, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { clientInfoAtom } from '../recoilAtoms';

import { styles } from './feedback.style';

import { apiFeedback } from '@services/api/feedback';


const Feedback: React.FC = () => {
  const classes = makeStyles(styles)();
  const [clientInfo, setClientInfo] = useRecoilState(clientInfoAtom);

  const [loading, setLoading] = useState(true); // 첫 랜딩 로딩써클
  useEffect(() => {
    setTimeout(() => setLoading(false), 500); // 0.5초 로딩 처리
  });


  const [completefeedback, setCompletefeedback] = useState(false);

  const [modalPrompt, setModalPrompt] = useState('');
  const handleModalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModalPrompt(event.target.value); // 입력값에 따라 modalPrompt 업데이트
  };

  const handleSend = async () => {
    if (completefeedback) { return }
    await apiFeedback(clientInfo.sid, modalPrompt)
    setCompletefeedback(true)
  };


  if (loading) {
    return (
      <Box className={classes.div_first_root}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress size={44} style={{ color: '#D84E98' }} />
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.div_first_root}>
      <Box className={classes.div_first}>
        <Box className={classes.title_box} >
          <Typography className={classes.title}>Please give us a Feedback! </Typography>
        </Box>
        <Box className={classes.text_box} >
          <Typography className={classes.text}>Your feedback helps us to imporve our product </Typography>
        </Box>
        <Box className={classes.info_box} >
          {completefeedback ? (
            <Typography className={classes.title}>Thanks for your feedback! </Typography>
          ) : (
            <TextField
              className={classes.modalTextField}
              value={modalPrompt} // 기본값으로
              onChange={handleModalChange} // 입력값 변경 시 호출되는 함수
              multiline // 여러 줄 입력 허용
              InputProps={{
                style: {
                  color: 'white', // 입력 텍스트 색상
                  // minHeight: '100px',
                  height: '100%',
                },
              }}
              InputLabelProps={{
                style: {
                  color: 'white', // 레이블 색상
                },
              }}
            />
          )}
        </Box>
        <Box className={classes.button_box} >
          <Button
            className={classes.button}
            onClick={() => handleSend()}
            sx={{
              textTransform: 'none',
            }}
          >
            Send
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export { Feedback };
