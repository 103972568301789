import { createStyles } from '@mui/styles';

import { WessistTheme } from '@common/style/themeSelector';

export const styles = (theme: WessistTheme) =>
  createStyles({
    root: {
      width: '100%',
      height: 'calc(100% - 0px)',
      display: 'flex',
      flexDirection: 'column',
      // borderLeft: '1px solid #8E8E93',
      // border: '1px solid #8E8E93',
    },
    rootleftborder: {
      borderLeft: '1px solid #8E8E93',
    },
    div_root: {
      width: '100%',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
    div_header: {
      width: '100%',
      height: '35px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0px 35px'
      // border: '1px solid #8E8E93',
    },
    div_header_left: {
      display: 'flex',
      flexDirection: 'row',
    },
    div_header_left_name: {
      fontSize: '20px',
      fontWeight: '900',
      color: theme.Wcolor.white,
    },
    div_header_left_remain: {
      fontSize: '20px',
      // fontWeight: '900',
      color: theme.Wcolor.white,
      whiteSpace: 'nowrap',
    },
    div_header_right: {
      width: 'auto',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    div_header_setbutton: {
      width: 'auto',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    div_header_set: {
      fontSize: '20px',
      // fontWeight: '900',
      color: theme.Wcolor.pink,
      whiteSpace: 'nowrap',
      marginRight: '8px',
    },
    divider: {
      height: '10px',
      cursor: 'row-resize',
      userSelect: 'none',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    divider_line: {
      height: '1px',
      width: '100%',
      backgroundColor: '#8E8E93',
    },
  });
