import React from 'react';

import { RecoilRoot } from 'recoil';

import { CssBaseline, StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material';
// import { hot } from 'react-hot-loader';
import { BrowserRouter as Router } from 'react-router-dom';

import themeSelector from '@common/style/themeSelector';
import { AppRoute } from '@routes';

export const getPlatform = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  if (/android/.test(userAgent)) {
    return 'android';
  }
  if (/iphone|ipad|ipod/.test(userAgent)) {
    return 'ios';
  }
  if (/windows|macintosh|macintel|mac os x/.test(userAgent)) {
    return 'computer';
  }
  return 'unknown';
};

const AppRenderer: React.FC = () => {
  const theme = createTheme(themeSelector({ platform: getPlatform() }));
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <AppRoute />
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const App: React.FC = () => {
  return (
    <RecoilRoot>
      <AppRenderer />
    </RecoilRoot>
  );
};

export default App;
