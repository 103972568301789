import { createAxiosInstance, config } from './baseapi';


interface TestLLMInterface {
  system: string
  text: string
  passwd: string
}

interface TestLLMResponse {
  msg_response: string;
}


async function apiTestLLMNovita1(system: string, text: string): Promise<string | null> {
  const data: TestLLMInterface = {
    system: system,
    text: text,
    passwd: config.passwd
  };

  try {
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.post<TestLLMResponse>('/api/test-llm-novita1', data);
    return response.data.msg_response;
  } catch (error) {
    console.error('Error during POST request:', error);
    return null;
  }
}


async function apiTestLLMNovita2(system: string, text: string): Promise<string | null> {
  const data: TestLLMInterface = {
    system: system,
    text: text,
    passwd: config.passwd
  };

  try {
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.post<TestLLMResponse>('/api/test-llm-novita2', data);
    return response.data.msg_response;
  } catch (error) {
    console.error('Error during POST request:', error);
    return null;
  }
}


async function apiTestLLMOpenai(system: string, text: string): Promise<string | null> {
  const data: TestLLMInterface = {
    system: system,
    text: text,
    passwd: config.passwd
  };

  try {
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.post<TestLLMResponse>('/api/test-llm-openai', data);
    return response.data.msg_response;
  } catch (error) {
    console.error('Error during POST request:', error);
    return null;
  }
}

export { apiTestLLMNovita1, apiTestLLMNovita2, apiTestLLMOpenai };
