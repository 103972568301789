import React, { useEffect, useState } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel, Button, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styles } from './admin.style';
import { useRecoilState } from 'recoil';
import { clientInfoAtom, pageAtom } from '../recoilAtoms';
import { createAxiosInstance } from '@services/api/baseapi';
import { useNavigate } from 'react-router-dom';

interface BaseResponse {
    id: number;
}

const Admin: React.FC = () => {
    const useStyles = makeStyles(styles);
    const classes = useStyles();
    const navigate = useNavigate();

    const [data, setData] = useState<any[]>([]);
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [columns, setColumns] = useState<string[]>([]);

    const [page, setPage] = useRecoilState(pageAtom);
    const [clientInfo, setClientInfo] = useRecoilState(clientInfoAtom);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (clientInfo.sid != 'doublemsudo1234' && clientInfo.sid != 'none') {
            navigate('/');
        }
    }, [clientInfo.sid]);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 500);
        setPage("/admin");
    }, []);

    const processBooleanValues = (data: any[]) => {
        return data.map(row => {
            const processedRow: any = {};
            for (const key in row) {
                if (typeof row[key] === 'boolean') {
                    processedRow[key] = row[key] ? 'true' : 'false'; // 문자열로 변환
                } else {
                    processedRow[key] = row[key]; // 다른 타입은 그대로 유지
                }
            }
            return processedRow;
        });
    };


    const fetchData = async (url: string) => {
        try {
            const axiosInstance = await createAxiosInstance();
            const response = await axiosInstance.get<BaseResponse[]>(`/api/${url}?passwd=boldsignal!`);
            if (response.data && response.data.length > 0) {
                const sortedData = response.data.sort((a: BaseResponse, b: BaseResponse) => b.id - a.id);
                const firstRow = response.data[0];
                setColumns(firstRow ? Object.keys(firstRow) : []);
                setData(processBooleanValues(sortedData)); // boolean 값 변환
                setOrder('asc');
            }
        } catch (error) {
            console.error('Error fetching data from API 1:', error);
        }
    };

    const handleSortById = () => {
        if (!data || data.length === 0) return;

        const sortedData = [...data].sort((a, b) => {
            if (order === 'asc') {
                return a.id - b.id;
            }
            return b.id - a.id;
        });
        setData(sortedData);
        setOrder(order === 'asc' ? 'desc' : 'asc');
    };

    if (loading) {
        return (
            <Box className={classes.root}>
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress size={44} style={{ color: '#D84E98' }} />
                </Box>
            </Box>
        );
    }

    return (
        <Box className={classes.root}>
            <Typography className={classes.title}>DB Table</Typography>
            <Box className={classes.buttonGroup}>
                <Button className={classes.button} onClick={() => fetchData('msg-history')}>
                    Work History
                </Button>
                <Button className={classes.button} onClick={() => fetchData('customerinfo')}>
                    User
                </Button>
                <Button className={classes.button} onClick={() => fetchData('character')}>
                    Character
                </Button>
                <Button className={classes.button} onClick={() => fetchData('chatlist')}>
                    Chatlist
                </Button>
                <Button className={classes.button} onClick={() => fetchData('chatitem')}>
                    ChatItem
                </Button>
                <Button className={classes.button} onClick={() => fetchData('imageitem')}>
                    ImageItem
                </Button>
                <Button className={classes.button} onClick={() => fetchData('feedback')}>
                    Feedback
                </Button>
                <Button className={classes.button} onClick={() => fetchData('shareddata')}>
                    Shareddata
                </Button>
                <Button className={classes.button} onClick={() => fetchData('sharedcharacter')}>
                    Sharedcharacter
                </Button>
                <Button className={classes.button} onClick={() => fetchData('patreonwebhook')}>
                    Patreon
                </Button>
                <Button className={classes.button} onClick={() => fetchData('hoster-log')}>
                    Log
                </Button>
            </Box>
            <TableContainer component={Paper} className={classes.tableContainer}>
                <Table className={classes.table} aria-label="dynamic table">
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            {columns.map((col) => (
                                <TableCell key={col} className={classes.tableCell}>
                                    {col === 'id' ? (
                                        <TableSortLabel
                                            className={classes.sortLabel}
                                            active={true}
                                            direction={order}
                                            onClick={handleSortById}
                                            style={{ color: '#fff' }}
                                        >
                                            {col.toUpperCase()}
                                        </TableSortLabel>
                                    ) : (
                                        col.toUpperCase()
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.length > 0 ? (
                            data.map((row, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    {columns.map((col) => (
                                        <TableCell key={col} className={classes.tableCell}>{row[col]}</TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length} align="center">
                                    No data available
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export { Admin };