// React 및 주요 라이브러리
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { saveAs } from 'file-saver';

// MUI 관련 컴포넌트와 스타일링
import { Box, CircularProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

// 전역 상태 관리 (Recoil Atoms)
import {
  ChatMessage,
  ChatImage,
  clientInfoAtom,
  chatInfoAtom,
  ClientInfo,
  pageAtom,
  pageHeight,
  sdmodelAtom,
  isAppOpen
} from '../recoilAtoms';

// 로컬 스타일 및 이미지 파일
import { styles } from './chatstream.style';
import stream_setup from '@common/assets/image/stream_setup.svg';
import icon_link from '@common/assets/image/upload.svg'


// API 서비스
import { apiGenImage } from '@services/api/genimg';
import { apiCustomerinfoGetmeta } from '@services/api/customerinfo';
import { apiChatlistFixedprompt, apiChatlistItem, apiChatlistSdmodel } from '@services/api/chatlist';
import { apiCheckImgResult } from '@services/api/checkimgresult';
import { apishareddata } from '@services/api/shareddata';
import { Logging } from '@services/api/hosterlog';

// 기타 로컬 컴포넌트
import { Nostream } from '../nostream/nostream';
import { ModalPage } from './modal';
import { TokenPage } from './token';
import { ChatBox } from './chatbox';
import { ImageBox } from './imagebox';
import { Apperaance } from './appearance';
import { SorrymodalPage } from './sorrymodal';

import {
  fetchCustomer,
  fetchChatitem,
  fetchImageitem,
  fetchChatlistItem,
  fetchSdmodelList,
} from './apiServices';

// 설정 파일
import { config } from '@services/api/baseapi';



interface ChatstreamProps {
  chatIdHistory: string;
}

const Chatstream: React.FC<ChatstreamProps> = ({ chatIdHistory }) => {
  const classes = makeStyles(styles)();
  const [_, setPage] = useRecoilState(pageAtom);
  const [clientInfo, setClientInfo] = useRecoilState(clientInfoAtom);
  const [chatInfo, setChatInfo] = useRecoilState(chatInfoAtom);
  const [chatid, setChatId] = useState('');
  const [prevChatId, setPrevChatId] = useState('');
  const [canRefresh, setCanRefresh] = useState(false);
  const [isStreamExist, setIsStreamExist] = useState(false); // 스트림 챗 있는지
  const [sdmodel, setSdmodelAtom] = useRecoilState(sdmodelAtom);
  const [modelNames, setModelNames] = useState<string[]>([]);

  const [blocking, setBlocking] = useState(false);
  const [loading, setLoading] = useState(true); // 첫 랜딩 로딩써클

  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [images, setImages] = useState<ChatImage[]>([]);

  const [waitGenImgButton, setWaitGenImgButton] = useState<boolean[]>([]); // 이미지 생성시 로딩써클
  const [isWaitGenImg, setIsWaitGenImg] = useState(false);

  // 시작
  useEffect(() => {
    const initializeData = async () => {
      if (!chatIdHistory) setPage('/streaming');

      setTimeout(() => setLoading(false), 500); // 0.5초 로딩 처리
      setTimeout(async () => setMessages(await fetchChatitem(clientInfo.sid, customerChatId)), 1000); // 스크롤용

      const customerChatId = await fetchCustomer(clientInfo.sid, chatIdHistory);

      if (customerChatId && customerChatId !== "" && clientInfo.sid !== 'none') {
        setIsStreamExist(true);
        setChatId(customerChatId);
        setPrevChatId(customerChatId);
        setMessages(await fetchChatitem(clientInfo.sid, customerChatId));
        setImages(await fetchImageitem(clientInfo.sid, customerChatId, config.baseurl));
        setModelNames(await fetchSdmodelList());

        const chatInfoData = await fetchChatlistItem(clientInfo.sid, customerChatId);
        setChatInfo(chatInfoData);
        setSdmodelAtom(chatInfoData?.sdmodel || "");
      }
    };
    initializeData();
    if (clientInfo.sid !== 'none') {
      Logging(clientInfo.sid, 'visit', 'chatstream', 'web')
    }
  }, [clientInfo.sid, chatIdHistory]);

  // 무한루프
  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    const clearIntervals = () => {
      if (intervalId) clearInterval(intervalId);
    };

    clearIntervals();

    const startIntervals = () => {
      const chatInterval = chatIdHistory ? 50000 : 1000;

      intervalId = setInterval(async () => {
        if (!isWaitGenImg) {
          const chatitems = await fetchChatitem(clientInfo.sid, chatid)
          if (chatitems.length > 0) {
            setMessages(chatitems);
          }
          const customerChatId = await fetchCustomer(clientInfo.sid, chatIdHistory);
          if (customerChatId !== chatid && customerChatId !== "") {
            setChatId(customerChatId);
          }
        }
      }, chatInterval);
    };

    const handleVisibilityChange = () => {
      document.visibilityState === "visible" ? startIntervals() : clearIntervals();
    };

    startIntervals();
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      clearIntervals();
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [clientInfo.sid, chatid, isWaitGenImg, chatIdHistory]);

  // 데이터 새로고침 함수
  const refreshData = async (newChatId: string) => {
    if (clientInfo.sid === 'none') {
      return
    }
    setLoading(true);
    setIsStreamExist(true);
    setMessages(await fetchChatitem(clientInfo.sid, newChatId));
    setImages(await fetchImageitem(clientInfo.sid, newChatId, config.baseurl));
    const chatInfoData = await fetchChatlistItem(clientInfo.sid, newChatId);
    setChatInfo(chatInfoData);
    setSdmodelAtom(chatInfoData?.sdmodel || "");
    setPrevChatId(chatid);
    setTimeout(() => setLoading(false), 500);
  };

  // 페이지 로드 1초 후부터 새로고침 허용
  useEffect(() => {
    const timer = setTimeout(() => {
      setCanRefresh(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  // chatid가 변경될 때 데이터 새로고침 (2초 후부터 작동)
  useEffect(() => {
    if (loading) { return }
    if (canRefresh && chatid !== '' && chatid !== prevChatId) {
      refreshData(chatid);
    }
  }, [chatid]);

  // 이미지 생성버튼
  const handleGenerateImage = async (index: number, idx: number, text: string) => {
    if (blocking) { return } else { setBlocking(true) }

    //토큰
    if (clientInfo.token <= 5) {
      setBlocking(false)
      setTokenModalTitle("Run out of Tokens!")
      setTokenModalOpen(true)
      return
    }

    // wait으로 전환
    const updatedwaitGenImgButton = [...waitGenImgButton];
    updatedwaitGenImgButton[index] = true;
    setWaitGenImgButton(updatedwaitGenImgButton);

    // 빈 이미지 요소를 상단에 추가
    const newImagePlaceholder = {
      id: -1,
      content: '',
      prompt: '',
      llmres: "",
      appearance: "",
      idx: idx,
      imageUrl: undefined,
      msgid: "",
    };
    setImages(prevImages => [newImagePlaceholder, ...prevImages]);

    // 이미지 생성 요청
    setIsWaitGenImg(true);
    const time = Date.now(); // 현재 시간을 밀리초 단위로 가져옴
    const msgid = `${clientInfo.sid}-${time}`; // msgid 생성
    await apiGenImage(clientInfo.sid, chatid, idx, text, msgid, false);

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      setIsWaitGenImg(false);
      setBlocking(false);

      const updatedwaitGenImgButton = [...waitGenImgButton];
      updatedwaitGenImgButton[index] = false;
      setWaitGenImgButton(updatedwaitGenImgButton);
      setImages(prevImages => prevImages.filter(img => img.id !== -1));
      setSorryOpen(true);
    }, 240000);

    // 결과 확인을 위한 interval 설정
    const intervalId = setInterval(async () => {
      const result = await apiCheckImgResult(msgid); // 이미지 결과 확인 API 호출

      if (result) { // 결과 완성
        clearInterval(intervalId);
        clearTimeout(timeoutId);
        setImages(await fetchImageitem(clientInfo.sid, chatid, config.baseurl));
        setIsWaitGenImg(false);

        // 토큰 업데이트
        const customerinfo = await apiCustomerinfoGetmeta(clientInfo.sid);
        if (customerinfo) {
          const clientinfo_result: ClientInfo = {
            sid: clientInfo.sid,
            email: customerinfo.email,
            plan: customerinfo.plan,
            nsfw: customerinfo.nsfw,
            token: customerinfo.usagelimit,
            paymeta: customerinfo.paymeta,
            paydue: customerinfo.paydue,
          };
          setClientInfo(clientinfo_result);
        }

        // 마지막 정리 작업
        const updatedwaitGenImgButton = [...waitGenImgButton];
        updatedwaitGenImgButton[index] = false;
        setWaitGenImgButton(updatedwaitGenImgButton);
        setBlocking(false)
      }
    }, 1000); // 1초마다 결과 확인
  };

  // 자동 스크롤
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(true);

  useEffect(() => {
    if (isAutoScrollEnabled && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, isAutoScrollEnabled]);

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
      const isUserAtBottom = scrollTop + clientHeight >= scrollHeight - 10;
      setIsAutoScrollEnabled(isUserAtBottom);
    }
  };

  // 페이지 사이즈 변경
  const [divImageHeight, setDivImageHeight] = useRecoilState(pageHeight);
  const [isImageExist, setIsImageExist] = useState(false);
  const [isResizing, setIsResizing] = useState(false);

  useEffect(() => {
    if (images.length == 0) {
      setIsImageExist(false)
    }
    if (images.length !== 0) {
      setIsImageExist(true);
    }
  }, [images]);

  const handleMouseDown = () => {
    setIsResizing(true);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isResizing) return;
    setDivImageHeight((prevHeight) => {
      const newHeight = prevHeight + e.movementY;
      return newHeight > 150 ? newHeight : 150; // Ensure a minimum height
    });
  };

  const handleMouseUp = () => {
    setIsResizing(false);
  };

  useEffect(() => {
    if (isResizing) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing]);


  const handleDownload = async (imageUrl: string) => {
    const response = await fetch(`${config.baseurl}/download-image?url=${encodeURIComponent(imageUrl)}`, {
      method: 'GET'
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
    }

    const blob = await response.blob();
    const filename = `fusio_${chatid}_${new Date().getTime()}.jpg`;
    saveAs(blob, filename);
  };

  // imagebox reset
  const resetImageList = async () => {
    setImages(await fetchImageitem(clientInfo.sid, chatid, config.baseurl));
  }

  // set sd model
  const setSdmode = async (sdmodel: string) => {
    if (blocking) { return } else { setBlocking(true) }
    await apiChatlistSdmodel(clientInfo.sid, chatid, sdmodel);
    const chatlistitem = await apiChatlistItem(clientInfo.sid, chatid);
    setSdmodelAtom(chatlistitem?.sdmodel || "")
    setBlocking(false)
  }

  //모달
  const [modalOpen, setMedalOpen] = useState(false);
  const [modalPrompt, setModalPrompt] = useState('');
  const [modalLlmres, setModalLlmres] = useState('');
  const [modalIdx, setModalIdx] = useState(0);
  const [modalSelectedImage, setModalSelectedImage] = useState<string | null>(null);
  const handleModalOpen = (imageUrl: string, prompt: string, llmres: string, idx: number) => {
    setModalSelectedImage(imageUrl); // 선택된 이미지를 저장
    setModalPrompt(prompt)
    setModalLlmres(llmres)
    setModalIdx(idx)
    setMedalOpen(true); // 모달 열림
  };

  const handleModalClose = () => {
    setMedalOpen(false); // 모달 닫힘
    setModalPrompt("")
    setModalLlmres("")
    setModalIdx(0)
    setModalSelectedImage(null); // 선택된 이미지 초기화
  };

  const handleModalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModalPrompt(event.target.value); // 입력값에 따라 modalPrompt 업데이트
  };


  // 이미지 재생성버튼
  const handleModalGenerateImagePrompt = async (idx: number, text: string) => {
    setMedalOpen(false)

    // global blocking
    if (blocking) { return } else { setBlocking(true) }

    //토큰
    if (clientInfo.token <= 5) {
      setBlocking(false)
      setTokenModalTitle("Run out of Tokens!")
      setTokenModalOpen(true)
      return
    }

    const newImagePlaceholder = {
      id: -1,  // 고유 ID로 임시 구분
      content: '',
      prompt: '',
      llmres: "",
      appearance: "",
      idx: idx,
      imageUrl: undefined,  // 아직 이미지가 없음
      msgid: "",
    };
    setImages(prevImages => [newImagePlaceholder, ...prevImages]);  // 빈 이미지 추가

    // 이미지 생성 요청
    setIsWaitGenImg(true);
    const time = Date.now(); // 현재 시간을 밀리초 단위로 가져옴
    const msgid = `${clientInfo.sid}-${time}`; // msgid 생성
    await apiGenImage(clientInfo.sid, chatid, idx, text, msgid, true);

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      setIsWaitGenImg(false);
      setBlocking(false);

      setImages(prevImages => prevImages.filter(img => img.id !== -1));
      setSorryOpen(true);
    }, 240000);

    // 결과 확인을 위한 interval 설정
    const intervalId = setInterval(async () => {
      const result = await apiCheckImgResult(msgid); // 이미지 결과 확인 API 호출

      if (result) { // 결과 완성
        clearInterval(intervalId);
        clearTimeout(timeoutId);
        setImages(await fetchImageitem(clientInfo.sid, chatid, config.baseurl));
        setIsWaitGenImg(false);

        //token 업데이트
        const customerinfo = await apiCustomerinfoGetmeta(clientInfo.sid);
        if (customerinfo) {
          const clientinfo_result: ClientInfo = {
            sid: clientInfo.sid,
            email: customerinfo.email,
            plan: customerinfo.plan,
            nsfw: customerinfo.nsfw,
            token: customerinfo.usagelimit,
            paymeta: customerinfo.paymeta,
            paydue: customerinfo.paydue,
          };
          setClientInfo(clientinfo_result);
        }
        setBlocking(false)
      }
    }, 1000); // 1초마다 결과 확인
  };

  // Apearance
  const [appOpen, setAppOpen] = useRecoilState(isAppOpen);
  const handleAppClose = () => {
    setAppOpen(false);
  }

  const handleAppOpen = () => {
    setAppOpen(true);
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const appearance_open = params.get('appearance') || "";
    if (appearance_open) {
      setAppOpen(true)
    }
  }, []);

  //token modal
  const [tokenModalTitle, setTokenModalTitle] = useState('');
  const [tokenModalOpen, setTokenModalOpen] = useState(false);
  const handleTokenModalClose = () => {
    setTokenModalOpen(false); // 모달 닫힘
  };

  //shared data
  const [noticeopen, setNoticeopen] = useState(false);
  const [noticeToken, setNoticeToken] = useState("");
  async function generateDataId(sid: string, time: number) {
    const encoder = new TextEncoder();
    const data = `share_${sid}_${time}`;
    const hashBuffer = await crypto.subtle.digest('SHA-256', encoder.encode(data));
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
    return `share_${hashHex}`;
  }

  const handleShareddata = async () => {
    if (blocking) { return } else { setBlocking(true) }
    const time = Date.now(); // 현재 시간을 밀리초 단위로 가져옴
    const dataid = await generateDataId(clientInfo.sid, time);
    const images_json = JSON.stringify(images)
    const messages_json = JSON.stringify(messages)
    const chatInfo_json = JSON.stringify(chatInfo)
    const result = await apishareddata(clientInfo.sid, clientInfo.email, dataid, images_json, messages_json, chatInfo_json, chatInfo.chatid, chatInfo.charactername, chatInfo.domain, chatInfo.img);
    if (result?.result === 'true') {
      setNoticeToken('true')
    }
    const sharedUrl = `${window.location.origin}/share?dataid=${dataid}`;
    await navigator.clipboard.writeText(sharedUrl);
    setNoticeopen(true)
    setTimeout(() => {
      setNoticeopen(false);
      setBlocking(false);
      setNoticeToken("");
    }, 2500);
  };

  //sorry modal
  const [sorryOpen, setSorryOpen] = useState(false);
  const handleSorryClose = () => {
    setSorryOpen(false);
  };


  if (loading) {
    return (
      <Box className={classes.root}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress size={44} style={{ color: '#D84E98' }} />
        </Box>
      </Box>
    );
  }

  if (!isStreamExist) {
    return (
      <Box className={classes.root}>
        <Nostream
          title='No chat has started'
          text=' If you start a chat with a character in a chat service,'
          text2='you can generate images right here.' />;
      </Box>
    );
  }

  if (appOpen) {
    return (
      <Box className={classes.root}>
        <Apperaance
          appOpen={appOpen}
          handleAppClose={handleAppClose}
          chatIdHistory={chatIdHistory}
          chatid={chatid}
        />
      </Box>
    )
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.div_root}>
        <Box className={classes.div_header}>
          <Box className={classes.div_header_left}>
            <Typography className={classes.div_header_left_name}>{chatInfo.charactername}</Typography>
            <Typography className={classes.div_header_left_remain}>, {chatInfo.domain}</Typography>
          </Box>
          <Box className={classes.div_header_right}>
            <Box className={classes.div_header_setbutton} onClick={handleShareddata}>
              <img
                src={icon_link}
                loading="lazy"
                style={{
                  marginRight: '8px',
                  marginLeft: '8px',
                  height: '24px',
                  width: '24px',
                }}
              />
            </Box>
            <Box className={classes.div_header_setbutton} onClick={handleAppOpen}>
              <img
                src={stream_setup}
                loading="lazy"
                style={{
                  marginRight: '8px',
                  marginLeft: '8px',
                  height: '24px',
                  width: '24px',
                }}
              />
              <Typography className={classes.div_header_set}>Set Appearance</Typography>
            </Box>
          </Box>
        </Box>
        {isImageExist && (
          <ImageBox
            divImageHeight={divImageHeight}
            images={images}
            handleDownload={handleDownload}
            handleModalOpen={handleModalOpen}
            handleModalGenerateImagePrompt={handleModalGenerateImagePrompt}
            resetImageList={resetImageList}
          />
        )}
        {isImageExist && (
          <Box
            className={classes.divider}
            onMouseDown={handleMouseDown}
          >
            <Box className={classes.divider_line}></Box>
          </Box>
        )}
        <ChatBox
          chatInfo={chatInfo}
          sdmodel={sdmodel}
          modelNames={modelNames}
          messages={messages}
          waitGenImgButton={waitGenImgButton}
          chatContainerRef={chatContainerRef}
          handleScroll={handleScroll}
          divImageHeight={divImageHeight}
          setSdmode={setSdmode}
          handleGenerateImage={handleGenerateImage}
          messagesEndRef={messagesEndRef}
        />
      </Box>

      <ModalPage
        modalOpen={modalOpen}
        handleModalClose={handleModalClose}
        handleModalGenerateImagePrompt={handleModalGenerateImagePrompt}
        modalSelectedImage={modalSelectedImage}
        modalPrompt={modalPrompt}
        modalLlmres={modalLlmres}
        modalIdx={modalIdx}
        handleModalChange={handleModalChange}
        chatid={chatid}
        chatIdHistory={chatIdHistory}
      />

      <TokenPage
        title={tokenModalTitle}
        tokenModalOpen={tokenModalOpen}
        handleTokenModalClose={handleTokenModalClose}
      />


      <SorrymodalPage
        modalOpen={sorryOpen}
        handleModalClose={handleSorryClose}
      />

      {noticeopen && (noticeToken === "true") && <Box className={classes.notice_with_token}>
        <Typography className={classes.notice_text}> Link for share</Typography>
        <Typography className={classes.notice_text}> Copy to Clipboard</Typography>
        <Typography className={classes.notice_text}> Get 10 token for today's share!</Typography>
      </Box>}

      {noticeopen && (noticeToken !== "true") && <Box className={classes.notice}>
        <Typography className={classes.notice_text}> Link for share</Typography>
        <Typography className={classes.notice_text}> Copy to Clipboard</Typography>
      </Box>}

    </Box >
  );
};

export { Chatstream };
