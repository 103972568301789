import { ThemeOptions } from '@mui/material';

// import NotoBold from '@common/assets/fonts/NotoSans-Bold.ttf';
// import NotoBoldItalic from '@common/assets/fonts/NotoSans-BoldItalic.ttf';
// import NotoItalic from '@common/assets/fonts/NotoSans-Italic.ttf';
import Sofia from '@common/assets/fonts/SofiaSansCondensed.ttf';

export interface WessistTheme extends ThemeOptions {
  Wcolor: {
    black: string;
    gray1: string;
    gray2: string;
    gray3: string;
    white: string;
    blue: string;
    red: string;
    yellow: string;
    lightblue: string;
    font: string;
    pink: string;
    pink2: string;
    lightpink: string;
  };
}

export interface ThemeProps {
  platform: string;
}

// Function That Generate theme Config
export const themeSelector = ({ platform }: ThemeProps): WessistTheme => {
  return {
    palette: {
      background: {},
    },
    Wcolor: {
      black: '#131313',
      gray1: '#8D8D8D',
      gray2: '#D1D1D1',
      gray3: '#2A2A2A',
      white: '#ffffff',
      blue: '#32BBD0',
      red: '#CF5134',
      yellow: '#E6E639',
      lightblue: '#6DBECB',
      font: '#202020',
      pink: '#D84E98',
      pink2: '#C35585',
      lightpink: '#D38CA1',
    },

    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            margin: '0',
            width: '100%',
            height: '100%',
          },
          body: {
            width: '100%',
            height: '100%',
            // maxWidth: '800px',
            // marginLeft: 'auto',
            // marginRight: 'auto',
            // backgroundColor: '#131313',
            // boxShadow: '0 0px 10px 10px rgba(0, 0, 0, 0.1)',
          },
          img: {
            userSelect: 'none',
            pointerEvents: 'none',
          },
          '#app': {
            margin: '0',
            width: '100%',
            height: '100%',
          },

          '@font-face': [
            {
              fontFamily: 'NotoSans',
              fontStyle: 'normal',
              // src: platform == 'ios' ? `url(${NotoBold}) format('truetype')` : `url(${Noto}) format('truetype')`,
              src: `url(${Sofia}) format('truetype')`,
            },
          ],

          // fallbacks: [
          //   {
          //     '@font-face': {
          //       fontFamily: 'NotoSans',
          //       fontStyle: 'italic',
          //       fontWeight: 'normal',
          //       src: `url(${NotoItalic}) format('truetype')`,
          //     },
          //   },
          //   {
          //     '@font-face': {
          //       fontFamily: 'NotoSans',
          //       fontStyle: 'italic',
          //       fontWeight: 'bold',
          //       src: `url(${NotoBoldItalic}) format('truetype')`,
          //     },
          //   },
          // ],
        },
      },
    },
    shape: {
      borderRadius: 0,
    },
    typography: {
      fontFamily: ['NotoSans'].join(','),
    },
  };
};
export default themeSelector;
