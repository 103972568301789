import { createAxiosInstance, config } from './baseapi';


interface checkImgResultInterface {
  passwd: string;
  msgid: string;
}

interface checkImgResultResponse {
  status: boolean; // 서버에서 이미지 URL을 반환한다는 가정
}

async function apiCheckImgResult(msgid: string): Promise<boolean | null> {
  const data: checkImgResultInterface = {
    passwd: 'boldsignal!',
    msgid: msgid,
  };

  try {
    // 서버에서 이미지 URL을 받아온다고 가정
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.post<checkImgResultResponse>('/api/check-img-result', data);
    // 반환된 URL을 사용
    return response.data.status;
  } catch (error) {
    console.error('Error during POST request:', error);
    return null;
  }
}



export { apiCheckImgResult };
