import { apiCustomerinfoGetstream } from '@services/api/customerinfo';
import { apiChatitemGetmeta, ChatitemResponse } from '@services/api/chatitem';
import { apiChatlistItem } from '@services/api/chatlist';
import { apiImageitemGetmeta, ImageitemResponse } from '@services/api/imageitem';
import { apiSdmodellist } from '@services/api/sdmodellist';

import {
    ChatMessage,
    ChatImage,
    ChatInfo,
} from '../recoilAtoms';

// 사용자 정보 불러오기 함수
export const fetchCustomer = async (sid: string, chatIdHistory: string): Promise<string> => {
    if (chatIdHistory !== "") {
        return chatIdHistory;
    }

    if (sid !== 'none') {
        const customerinfo = await apiCustomerinfoGetstream(sid);
        return customerinfo?.chatstream ?? '';
    }

    return '';
};

// 채팅 정보 불러오기 함수
export const fetchChatitem = async (sid: string, chatid: string): Promise<ChatMessage[]> => {
    if (!chatid) return [];

    const chatitems = await apiChatitemGetmeta(sid, chatid);
    if (chatitems && chatitems.length > 0) {
        return chatitems
            .map((item: ChatitemResponse) => ({
                text: item.content,
                idx: item.idx,
                ischaracter: item.ischaracter,
                imageUrl: undefined,
            }))
            .sort((a, b) => a.idx - b.idx);
    }

    return [];
};

// 이미지 정보 불러오기 함수
export const fetchImageitem = async (sid: string, chatid: string, baseurl: string): Promise<ChatImage[]> => {
    if (!chatid) return [];

    const imageItems = await apiImageitemGetmeta(sid, chatid);
    if (imageItems && imageItems.length > 0) {
        return imageItems
            .map((item: ImageitemResponse) => ({
                id: item.id,
                content: item.content,
                prompt: item.prompt,
                llmres: item.llmres,
                idx: item.idx,
                msgid: item.msgid,
                imageUrl: item.img !== "" ? `${baseurl}/api/images/${sid}/${chatid}/${item.img}` : undefined,
            }))
            .sort((a, b) => b.id - a.id);
    }

    return [];
};

// 채팅 목록 불러오기 함수
export const fetchChatlistItem = async (sid: string, chatid: string): Promise<ChatInfo> => {
    if (!chatid || sid === "none") return { chatid: '', img: '', domain: '', charactername: '', sdmodel: '', fixedprompt: '', appearance: '' };

    const chatlistitem = await apiChatlistItem(sid, chatid);
    return {
        chatid: chatlistitem?.chatid || "",
        img: chatlistitem?.img || "",
        domain: chatlistitem?.domain || "",
        charactername: chatlistitem?.charactername || "",
        sdmodel: chatlistitem?.sdmodel || "",
        fixedprompt: chatlistitem?.fixedprompt || "",
        appearance: chatlistitem?.appearance || "",
    };
};


// SD model 목록 불러오기 함수
export const fetchSdmodelList = async (): Promise<string[]> => {
    const names = await apiSdmodellist()
    if (!names) return [];
    return names
};


