import { createStyles } from '@mui/styles';

import { WessistTheme } from '@common/style/themeSelector';

export const styles = (theme: WessistTheme) =>
  createStyles({
    div_first_root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px',
    },
    div_first: {
      width: '100%',
      maxWidth: '400px',
      height: '100%',
      maxHeight: '300px',
      padding: '12px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'center',
    },
    prev_wrap: {
      width: '132px',
      height: '30px',
    },
    title: {
      fontSize: '38px',
      fontWeight: '700',
      color: theme.Wcolor.white,
    },
    loginbutton: {
      width: '100%',
      maxWidth: '100%',
      height: '48px',
      fontSize: '22px',
      color: theme.Wcolor.white,
      backgroundColor: theme.Wcolor.pink2,
      borderRadius: '12px',
      marginTop: '32px',
    },
    text_wrap_parent: {
      width: '100%',
      height: '20px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '20px',
    },
    text_wrap: {
      width: 'auto',
      height: '20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    text: {
      fontSize: '20px',
      fontWeight: '300',
      color: theme.Wcolor.white,
      marginRight: '12px',
    },
    text2: {
      fontSize: '20px',
      fontWeight: '500',
      color: theme.Wcolor.pink2,
    },
  });
