// React 및 주요 라이브러리
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { saveAs } from 'file-saver';

// MUI 관련 컴포넌트와 스타일링
import { Box, CircularProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

// 전역 상태 관리 (Recoil Atoms)
import {
  ChatMessage,
  ChatImage,
  chatInfoAtom,
  pageHeight,
  isAppOpen,
  ChatInfo
} from '../recoilAtoms';

// 로컬 스타일 및 이미지 파일
import { styles } from './share.style';
import stream_setup from '@common/assets/image/stream_setup.svg';

// API 서비스
import { apishareddataGetmeta } from '@services/api/shareddata';
import { Logging } from '@services/api/hosterlog';

// 기타 로컬 컴포넌트
import { ModalPage } from './modal';
import { ChatBox } from './chatbox';
import { ImageBox } from './imagebox';
import { Apperaance } from './appearance';

// 설정 파일
import { config } from '@services/api/baseapi';


const Share: React.FC = () => {
  const params = new URLSearchParams(location.search);
  const dataid = params.get('dataid') || "";

  const classes = makeStyles(styles)();
  const [chatInfo, setChatInfo] = useRecoilState(chatInfoAtom);

  const [loading, setLoading] = useState(true); // 첫 랜딩 로딩써클

  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [images, setImages] = useState<ChatImage[]>([]);


  // 시작
  useEffect(() => {
    const initializeData = async () => {
      setTimeout(() => setLoading(false), 500); // 0.5초 로딩 처리

      const data = await apishareddataGetmeta(dataid)
      if (data) {
        const loadedMessages: ChatMessage[] = JSON.parse(data.messages);
        const loadedImages: ChatImage[] = JSON.parse(data.images);
        const loadChatInfo: ChatInfo = JSON.parse(data.chatinfo);

        setImages(loadedImages);
        setChatInfo(loadChatInfo);
        setTimeout(async () => setMessages(loadedMessages), 1000);
      }
    };
    initializeData();
    Logging('', 'visit', 'share', dataid)
  }, []);


  // 이미지 생성버튼
  const handleGenerateImage = async (index: number, idx: number, text: string) => {

  };

  // 자동 스크롤
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(true);

  useEffect(() => {
    if (isAutoScrollEnabled && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, isAutoScrollEnabled]);

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
      const isUserAtBottom = scrollTop + clientHeight >= scrollHeight - 10;
      setIsAutoScrollEnabled(isUserAtBottom);
    }
  };

  // 페이지 사이즈 변경
  const [divImageHeight, setDivImageHeight] = useRecoilState(pageHeight);
  const [isImageExist, setIsImageExist] = useState(false);
  const [isResizing, setIsResizing] = useState(false);

  useEffect(() => {
    if (images.length == 0) {
      setIsImageExist(false)
    }
    if (images.length !== 0) {
      setIsImageExist(true);
    }
  }, [images]);

  const handleMouseDown = () => {
    setIsResizing(true);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isResizing) return;
    setDivImageHeight((prevHeight) => {
      const newHeight = prevHeight + e.movementY;
      return newHeight > 150 ? newHeight : 150; // Ensure a minimum height
    });
  };

  const handleMouseUp = () => {
    setIsResizing(false);
  };

  useEffect(() => {
    if (isResizing) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing]);


  // 이미지다운로드
  const handleDownload = async (imageUrl: string) => {
    const response = await fetch(`${config.baseurl}/download-image?url=${encodeURIComponent(imageUrl)}`, {
      method: 'GET'
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
    }

    const blob = await response.blob();
    const filename = `fusio_share_${new Date().getTime()}.jpg`;
    saveAs(blob, filename);
  };

  //모달
  const [modalOpen, setMedalOpen] = useState(false);
  const [modalPrompt, setModalPrompt] = useState('');
  const [modalLlmres, setModalLlmres] = useState('');
  const [modalSelectedImage, setModalSelectedImage] = useState<string | null>(null);
  const handleModalOpen = (imageUrl: string, prompt: string, llmres: string, idx: number) => {
    setModalSelectedImage(imageUrl); // 선택된 이미지를 저장
    setModalPrompt(prompt)
    setModalLlmres(llmres)
    setMedalOpen(true); // 모달 열림
  };

  const handleModalClose = () => {
    setMedalOpen(false); // 모달 닫힘
    setModalPrompt("")
    setModalLlmres("")
    setModalSelectedImage(null); // 선택된 이미지 초기화
  };

  const handleModalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  };

  // Apearance
  const [appOpen, setAppOpen] = useRecoilState(isAppOpen);
  const handleAppClose = () => {
    setAppOpen(false);
  }

  const handleAppOpen = () => {
    setAppOpen(true);
  }


  if (loading) {
    return (
      <Box className={classes.root}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress size={44} style={{ color: '#D84E98' }} />
        </Box>
      </Box>
    );
  }

  if (appOpen) {
    return (
      <Box className={classes.root}>
        <Apperaance
          handleAppClose={handleAppClose}
          chatInfo={chatInfo}
        />
      </Box>
    )
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.div_root}>
        <Box className={classes.div_header}>
          <Box className={classes.div_header_left}>
            <Typography className={classes.div_header_left_name}>{chatInfo.charactername}</Typography>
            <Typography className={classes.div_header_left_remain}>, {chatInfo.domain}</Typography>
          </Box>
          <Box className={classes.div_header_right}>
            <Box className={classes.div_header_setbutton} onClick={handleAppOpen}>
              <img
                src={stream_setup}
                loading="lazy"
                style={{
                  marginRight: '8px',
                  marginLeft: '8px',
                  height: '24px',
                  width: '24px',
                }}
              />
              <Typography className={classes.div_header_set}>Set Appearance</Typography>
            </Box>
          </Box>
        </Box>
        {isImageExist && (
          <ImageBox
            divImageHeight={divImageHeight}
            images={images}
            handleDownload={handleDownload}
            handleModalOpen={handleModalOpen}
          />
        )}
        {isImageExist && (
          <Box
            className={classes.divider}
            onMouseDown={handleMouseDown}
          >
            <Box className={classes.divider_line}></Box>
          </Box>
        )}
        <ChatBox
          chatInfo={chatInfo}
          messages={messages}
          chatContainerRef={chatContainerRef}
          handleScroll={handleScroll}
          divImageHeight={divImageHeight}
          handleGenerateImage={handleGenerateImage}
          messagesEndRef={messagesEndRef}
        />
      </Box>

      <ModalPage
        modalOpen={modalOpen}
        handleModalClose={handleModalClose}
        modalSelectedImage={modalSelectedImage}
        modalPrompt={modalPrompt}
        modalLlmres={modalLlmres}
        handleModalChange={handleModalChange}
        chatInfo={chatInfo}
      />

    </Box >
  );
};

export { Share };
