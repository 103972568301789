import { createAxiosInstance, config } from './baseapi';

interface shareddata {
  sid: string;
  email: string;
  dataid: string;
  images: string;
  messages: string;
  chatinfo: string;
  chatid: string;
  charactername: string;
  domain: string;
  img: string;
  passwd: string;
}



interface shareddataResponse {
  result: string;
}

async function apishareddata(sid: string, email: string, dataid: string, images: string, messages: string, chatinfo: string, chatid: string, charactername: string, domain: string, img: string): Promise<shareddataResponse | null> {
  const data: shareddata = {
    sid: sid,
    email: email,
    dataid: dataid,
    images: images,
    messages: messages,
    chatinfo: chatinfo,
    chatid: chatid,
    charactername: charactername,
    domain: domain,
    img: img,
    passwd: config.passwd,
  };

  try {
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.post('/api/shareddata', data);
    return response.data;
  } catch (error) {
    console.error('Error during POST request:', error);
    return null;
  }
}



interface shareddataGetmeta {
  dataid: string;
  passwd: string;
}

interface shareddataGetmetaResponse {
  dataid: string;
  images: string;
  messages: string;
  chatinfo: string;
}

async function apishareddataGetmeta(dataid: string): Promise<shareddataGetmetaResponse | null> {
  const data: shareddataGetmeta = {
    dataid: dataid,
    passwd: config.passwd,
  };

  try {
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.post<shareddataGetmetaResponse>('/api/shareddata-getmeta', data);
    return response.data;
  } catch (error) {
    console.error('Error during POST request:', error);
    return null;
  }
}

interface shareddataGetlist {
  charactername: string;
  domain: string;
  passwd: string;
}

interface shareddataGetlistResponse {
  chatinfo: string;
  dataid: string;
}

async function apishareddataGetlist(charactername: string, domain: string): Promise<shareddataGetlistResponse[] | null> {
  const data: shareddataGetlist = {
    charactername: charactername,
    domain: domain,
    passwd: config.passwd,
  };

  try {
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.post<shareddataGetlistResponse[]>('/api/shareddata-list', data);
    return response.data;
  } catch (error) {
    console.error('Error during POST request:', error);
    return null;
  }
}

export {
  apishareddata,
  apishareddataGetmeta,
  apishareddataGetlist,
  shareddataGetmetaResponse
};


