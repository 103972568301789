import { createStyles } from '@mui/styles';

import { WessistTheme } from '@common/style/themeSelector';

export const styles = (theme: WessistTheme) =>
  createStyles({
    root: {
      height: `calc(100% - 0px)`,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: '20px',
      // borderLeft: '1px solid #ddd',
      overflowY: 'auto',
    },
    title: {
      color: "#ffffff", // 글자색 (흰색)
      fontSize: '20px',   // 글자 크기
    },
    buttonGroup: {
      display: 'flex',
      flexDirection: 'row',
    },
    button: {
      width: '100px',
      height: '50px',
      margin: '5px',
      borderRadius: '10px',
      backgroundColor: theme.Wcolor.gray1,
      color: theme.Wcolor.white,
    },
    tableContainer: {
      backgroundColor: theme.Wcolor.black, // 테이블 전체 배경색
      '&::-webkit-scrollbar': {
        width: '8px', // 스크롤바 너비
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888', // 스크롤바 색상
        borderRadius: '4px', // 스크롤바 둥글게
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#555', // 마우스 오버 시 색상 변경
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: theme.Wcolor.black, // 스크롤바 트랙 배경 색상
      },
    },
    table: {
      maxWidth: '100%',
      width: '100%',
    },
    tableHead: {
      backgroundColor: theme.Wcolor.gray3, // 헤더 배경색
    },
    tableCell: {
      color: '#fff', // 텍스트 색상 흰색
    },
    tableRow: {
      height: '50px', // 행 높이 조정
    },
    sortLabel: {
      color: '#fff !important', // 화살표와 텍스트 색상 흰색
      '& .MuiTableSortLabel-icon': {
        color: '#fff !important', // 화살표 색상 흰색
      },
    },
  });
