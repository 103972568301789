import { createStyles } from '@mui/styles';

import { WessistTheme } from '@common/style/themeSelector';

export const styles = (theme: WessistTheme) =>
  createStyles({
    root: {
      height: `calc(100% - 0px)`,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: '20px',
      // borderLeft: '1px solid #ddd',
    },
    root_main: {
      height: `calc(100% - 0px)`,
      width: '100%',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      '&::-webkit-scrollbar': {
        width: '8px', // 스크롤바 너비
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888', // 스크롤바 색상
        borderRadius: '4px', // 스크롤바 둥글게
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#555', // 마우스 오버 시 색상 변경
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: theme.Wcolor.black, // 스크롤바 트랙 배경 색상
      },
    },
    title: {
      width: 'auto',
      height: '50px',
      marginBottom: '10px',
      color: theme.Wcolor.white,
      fontSize: '22px',
      fontWeight: '700',
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
    },
    chat_list_item: {
      width: 'auto',
      maxWidth: '500px',
      height: '100px',
      marginBottom: '10px',
      backgroundColor: theme.Wcolor.gray3,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: '12px',
    },
    chat_img_box: {
      width: 'auto',
      maxWidth: '400px',
      height: '100px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'start',
    },
    chat_img: {
      width: '100px',
      height: '100px',
      marginRight: '10px',
    },
    chat_desc: {
      width: 'auto',
      maxWidth: '300px',
      height: '100px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'start',
    },
    chat_name: {
      fontSize: '20px',
      color: theme.Wcolor.white,
    },
    chat_num: {
      fontSize: '16px',
      fontWeight: '100',
      color: theme.Wcolor.white,
    },
    chat_buttons: {
      width: '100px',
      maxWidth: '100px',
      height: '100px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'end',
      '&:hover': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      }
    },
    delete_button: {
      width: '100px',
      height: '50px',
      color: theme.Wcolor.white,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'end',
      '&:hover': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      }
    },
  });
