// React 및 주요 라이브러리
import React, { useState } from 'react';

// MUI 관련 컴포넌트와 스타일링
import { Box, Button, Modal, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

// 로컬 스타일 및 이미지 파일
import { styles } from './patreonmodal.style';
import patreon_logo from '@common/assets/image/patreon_logo.svg';
import close_white from '@common/assets/image/close_white.svg';


type PatreonModalProps = {
  patreonModalOpen: boolean;
  handleModalClose: () => void;
  handleClinkPatreon: () => void;

};

const PatreonModal: React.FC<PatreonModalProps> = ({
  patreonModalOpen,
  handleModalClose,
  handleClinkPatreon,
}) => {
  const classes = makeStyles(styles)();

  return (

    <Modal open={patreonModalOpen} onClose={handleModalClose}>
      <Box
        className={classes.modalOverlay}
        onClick={handleModalClose}
      >
        <Box
          className={classes.modalContent}
          onClick={(e) => e.stopPropagation()}
        >
          <Box
            className={classes.closebox}
          >
            <Box
              className={classes.imgwrap}
              onClick={handleModalClose}
            >
              <img
                src={close_white}
                style={{ maxHeight: '32px', maxWidth: '32px' }}
              />
            </Box>
          </Box>
          <Typography className={classes.modalTitle}>Are you a</Typography>
          <Typography className={classes.modalTitle}>Patreon supporter?</Typography>
          <Box className={classes.buttonBox}>
            <Button
              className={classes.title_patreon_button}
              onClick={() => handleClinkPatreon()}
              sx={{
                textTransform: 'none',
              }}
            >
              <img
                src={patreon_logo}
                style={{ maxHeight: '32px', maxWidth: '32px' }}
              />
              Connect with Patreon
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export { PatreonModal };
