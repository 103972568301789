// React 및 주요 라이브러리
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

// MUI 관련 컴포넌트와 스타일링
import { Box, Button, Modal, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { chatInfoAtom, clientInfoAtom } from '../recoilAtoms';


// 로컬 스타일 및 이미지 파일
import { styles } from './token.style';
import subs_check from '@common/assets/image/subs_check.svg';

// api
import { bubbleUrl } from '@services/api/baseapi';

type TokenPageProps = {
  title: string;
  tokenModalOpen: boolean;
  handleTokenModalClose: () => void;
};

const TokenPage: React.FC<TokenPageProps> = ({
  title,
  tokenModalOpen,
  handleTokenModalClose,
}) => {
  const classes = makeStyles(styles)();

  const handleOpenWebsite = () => {
    window.location.href = `${bubbleUrl}/store`
  };


  return (

    <Modal
      open={tokenModalOpen}
      onClose={handleTokenModalClose}
      disablePortal // Portal을 비활성화해서 렌더링 충돌을 줄임
      disableEnforceFocus // 포커스 강제 이동을 비활성화
    >
      <Box
        className={classes.modalOverlay}
        onClick={handleTokenModalClose} // 모달 바깥을 클릭했을 때만 모달이 닫히도록 설정
      >
        <Box
          className={classes.modalContent1}
          onClick={(e) => e.stopPropagation()} // 모달 내부 클릭 시 닫히지 않도록 설정
        >
          <Box
            className={classes.modalContent2}
            onClick={(e) => e.stopPropagation()} // 모달 내부 클릭 시 닫히지 않도록 설정
          >
            <Typography className={classes.title_title}>{title}</Typography>
            <Typography className={classes.title_first_title}>Upgrade to generate</Typography>
            <Typography className={classes.title_first_state}> More Uncensored Photos </Typography>
            <Box className={classes.info_line}>
              <img
                src={subs_check}
                alt="Selected"
                style={{ maxHeight: '32px', maxWidth: '32px' }}
              />
              <Typography className={classes.info}>More token </Typography>
            </Box>
            <Box className={classes.info_line}>
              <img
                src={subs_check}
                alt="Selected"
                style={{ maxHeight: '32px', maxWidth: '32px' }}
              />
              <Typography className={classes.info}>More characters </Typography>
            </Box>
            <Box className={classes.info_line}>
              <img
                src={subs_check}
                alt="Selected"
                style={{ maxHeight: '32px', maxWidth: '32px' }}
              />
              <Typography className={classes.info}>Faster Image Generation </Typography>
            </Box>
            <Button
              className={classes.basic_button}
              onClick={() => handleOpenWebsite()}
              sx={{
                textTransform: 'none',
              }}
            >
              Subscribe
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal >
  );
};

export { TokenPage };
