import { createAxiosInstance, config } from './baseapi';


interface ChatlistGetmeta {
  sid: string;
  passwd: string;
}

interface ChatlistResponse {
  id: number;
  chatid: string;
  charactername: string;
  sid: string;
  domain: string;
  img: string;
  msgnum: number;
}

async function apiChatlistGetmeta(sid: string): Promise<ChatlistResponse[] | null> {
  const data: ChatlistGetmeta = {
    sid: sid,
    passwd: config.passwd,
  };
  try {
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.post<ChatlistResponse[]>('/api/chatlist-getmeta', data);
    return response.data;
  } catch (error) {
    console.error('Error during POST request:', error);
    return null;
  }
}

interface ChatlistDel {
  chatid: string;
  sid: string;
  passwd: string;
}

async function apiChatlistDel(chatid: string, sid: string): Promise<void> {
  const data: ChatlistDel = {
    chatid: chatid,
    sid: sid,
    passwd: config.passwd,
  };
  try {
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.post('/api/chatlist-delitem', data);
    if (response.status !== 200) {
      console.warn('Error during POST', response.status);
    }
  } catch (error) {
    console.error('Error during POST request:', error);
    // 에러가 발생했을 때 처리할 로직 추가 가능
  }
}


interface ChatlistItem {
  sid: string;
  chatid: string;
  passwd: string;
}

interface ChatlistItemResponse {
  chatid: string;
  sid: string;
  domain: string;
  img: string;
  charactername: string;
  sdmodel: string;
  fixedprompt: string;
  appearance: string;
}


async function apiChatlistItem(sid: string, chatid: string): Promise<ChatlistItemResponse | null> {
  const data: ChatlistItem = {
    sid: sid,
    chatid: chatid,
    passwd: config.passwd,
  };
  try {
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.post<ChatlistItemResponse>('/api/chatlist-item', data);
    return response.data;
  } catch (error) {
    console.error('Error during POST request:', error);
    return null;
  }
}

interface ChatlistSdmodel {
  sid: string;
  chatid: string;
  passwd: string;
  sdmodel: string;
}


async function apiChatlistSdmodel(sid: string, chatid: string, sdmodel: string): Promise<void> {
  const data: ChatlistSdmodel = {
    sid: sid,
    chatid: chatid,
    sdmodel: sdmodel,
    passwd: config.passwd,
  };
  try {
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.post('/api/chatlist-sdmodel', data);
    if (response.status !== 200) {
      console.warn('Error during POST', response.status);
    }
  } catch (error) {
    console.error('Error during POST request:', error);
  }
}

interface ChatlistFixedprompt {
  sid: string;
  chatid: string;
  passwd: string;
  fixedprompt: string;
}

async function apiChatlistFixedprompt(sid: string, chatid: string, fixedprompt: string): Promise<void> {
  const data: ChatlistFixedprompt = {
    sid: sid,
    chatid: chatid,
    fixedprompt: fixedprompt,
    passwd: config.passwd,
  };
  try {
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.post('/api/chatlist-fixedprompt', data);
    if (response.status !== 200) {
      console.warn('Error during POST', response.status);
    }
  } catch (error) {
    console.error('Error during POST request:', error);
  }
}


interface ChatlistAppearance {
  sid: string;
  chatid: string;
  passwd: string;
  appearance: string;
}

async function apiChatlistAppearance(sid: string, chatid: string, appearance: string): Promise<void> {
  const data: ChatlistAppearance = {
    sid: sid,
    chatid: chatid,
    appearance: appearance,
    passwd: config.passwd,
  };
  try {
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.post('/api/chatlist-appearance', data);
    if (response.status !== 200) {
      console.warn('Error during POST', response.status);
    }
  } catch (error) {
    console.error('Error during POST request:', error);
  }
}


export {
  apiChatlistGetmeta,
  ChatlistResponse,
  apiChatlistDel,
  apiChatlistItem,
  apiChatlistSdmodel,
  apiChatlistFixedprompt,
  apiChatlistAppearance
};
