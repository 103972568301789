import { createAxiosInstance, config } from './baseapi';

interface ChatitemGetmeta {
  sid: string;
  chatid: string;
  passwd: string;
}

interface ChatitemResponse {
  content: string;
  ischaracter: boolean;
  idx: number;
}

async function apiChatitemGetmeta(sid: string, chatid: string): Promise<ChatitemResponse[] | null> {
  const data: ChatitemGetmeta = {
    sid: sid,
    chatid: chatid,
    passwd: config.passwd,
  };

  try {
    // createAxiosInstance()를 호출하여 axios 인스턴스를 얻음
    const axiosInstance = await createAxiosInstance(); // 비동기적으로 axios 인스턴스를 생성
    const response = await axiosInstance.post<ChatitemResponse[]>('/api/chatitem-getmeta', data);
    return response.data;
  } catch (error) {
    console.error('Error during POST request:', error);
    return null;
  }
}


export { apiChatitemGetmeta, ChatitemResponse };
