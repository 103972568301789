import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

// MUI 관련 컴포넌트와 스타일링
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { apiChatlistGetmeta, ChatlistResponse, apiChatlistDel } from '@services/api/chatlist';

// 전역 상태 관리 (Recoil Atoms)
import { clientInfoAtom, pageAtom } from '../recoilAtoms';

// 로컬 스타일 및 이미지 파일
import { styles } from './chatlist.style';

// 설정 파일
import { config } from '@services/api/baseapi';
import { Logging } from '@services/api/hosterlog';

// 기타 로컬 컴포넌트
import { ModalPage } from './modal';


const useStyles = makeStyles(styles);

const Chatlist: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [clientInfo, setClientInfo] = useRecoilState(clientInfoAtom);
    const [chatList, setChatList] = useState<ChatlistResponse[]>([]);
    const [page, setPage] = useRecoilState(pageAtom);
    const [loading, setLoading] = useState(true);  // 로딩 상태

    const [refreshKey, setRefreshKey] = useState(0);

    useEffect(() => {
        setPage("/chatlist");
        if (clientInfo.sid !== 'none') {
            Logging(clientInfo.sid, 'visit', 'chatlist', 'web')
        }

        const fetchCustomerInfo = async () => {
            try {
                if (clientInfo.sid && clientInfo.sid !== "none") {
                    const customerinfo = await apiChatlistGetmeta(clientInfo.sid);
                    if (Array.isArray(customerinfo)) {
                        const sortedChatList = customerinfo
                            .filter(info => info.sid && info.img)
                            .sort((a, b) => b.id - a.id);
                        setChatList(sortedChatList);
                    }
                }
            } catch (error) {
                // console.error("Error fetching chatlist:", error);
            } finally {
                // 첫 번째 로딩 후 로딩 스피너를 없앰
                setLoading(false);
            }
        };

        fetchCustomerInfo();

        // 10초마다 데이터 업데이트
        const intervalId = setInterval(() => {
            fetchCustomerInfo();
        }, 10000);  // 10초 간격으로 실행

        return () => {
            clearInterval(intervalId);
            setChatList([]);
        };
    }, [clientInfo.sid, refreshKey]);

    const handleChatClick = (chatId: string) => {
        navigate(`/chat?chatid=${chatId}`);
    };

    const handleAppearanceClick = (chatId: string) => {
        navigate(`/chat?chatid=${chatId}&appearance=true`);
    };

    const [modalOpen, setMedalOpen] = useState(false);
    const [modalSid, setModalSid] = useState('');
    const [modalChatid, setModalChatid] = useState('');
    const handleModalOpen = (chatid: string, sid: string) => {
        setMedalOpen(true); // 모달 닫힘
        setModalSid(sid)
        setModalChatid(chatid)
    };
    const handleModalClose = async () => {
        setMedalOpen(false); // 모달 닫힘
    };

    const handleDeleteChat = async () => {
        await apiChatlistDel(modalChatid, modalSid);
        setChatList(prevChatList => prevChatList.filter(chat => chat.sid !== modalSid));
        setRefreshKey(prev => prev + 1);
        setMedalOpen(false);
    };

    return (
        <Box className={classes.root}>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress size={44} style={{ color: '#D84E98' }} />
                </Box>
            ) : (
                <Box className={classes.root_main}>
                    <Typography className={classes.title}>{`${chatList.length} Characters`}</Typography>
                    {chatList.map((chatInfo, index) => (
                        <Box
                            className={classes.chat_list_item}
                            key={index}
                            onClick={() => handleChatClick(chatInfo.chatid)}
                        >
                            <Box className={classes.chat_img_box}>
                                <img
                                    src={`${config.baseurl}/api/images/${chatInfo.img}`}
                                    alt={`${chatInfo.img}의 이미지`}
                                    className={classes.chat_img}
                                />
                                <Box className={classes.chat_desc}>
                                    <Typography className={classes.chat_name}>Move to {chatInfo.charactername}</Typography>
                                    <Typography className={classes.chat_num}>{chatInfo.domain}</Typography>
                                    <Typography className={classes.chat_num}>{chatInfo.msgnum} messages</Typography>
                                </Box>
                            </Box>

                            <Box className={classes.chat_buttons}>
                                <Button
                                    className={classes.delete_button}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleAppearanceClick(chatInfo.chatid);
                                    }}
                                    sx={{
                                        textTransform: 'none',
                                    }}
                                >
                                    Edit Appearance
                                </Button>
                                <Button
                                    className={classes.delete_button}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleModalOpen(chatInfo.chatid, chatInfo.sid);
                                    }}
                                    sx={{
                                        textTransform: 'none',
                                    }}
                                >
                                    Delete
                                </Button>
                            </Box>
                        </Box>
                    ))}
                </Box>
            )}

            <ModalPage
                modalOpen={modalOpen}
                handleModalClose={handleModalClose}
                handleDeleteChat={handleDeleteChat}
            />

        </Box>
    );
};



export { Chatlist };
