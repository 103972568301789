import { createAxiosInstance, config } from './baseapi';

interface CustomerinfoGetmeta {
  sid: string;
  passwd: string;
}

interface CustomerinfoResponse {
  email: string;
  plan: string;
  nsfw: boolean;
  usagelimit: number;
  chatstream: string;
  activate: number;
  paymeta: string;
  paydue: string;
}


async function apiCustomerinfoGetmeta(sid: string): Promise<CustomerinfoResponse | null> {
  const data: CustomerinfoGetmeta = {
    sid: sid,
    passwd: config.passwd,
  };

  try {
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.post<CustomerinfoResponse>('/api/customerinfo-getmeta', data);
    return response.data;
  } catch (error) {
    console.error('Error during POST request:', error);
    return null;
  }
}


async function apiCustomerinfoGetstream(sid: string): Promise<CustomerinfoResponse | null> {
  const data: CustomerinfoGetmeta = {
    sid: sid,
    passwd: config.passwd,
  };

  try {
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.post<CustomerinfoResponse>('/api/customerinfo-getstream', data);
    return response.data;
  } catch (error) {
    console.error('Error during POST request:', error);
    return null;
  }
}

interface CustomerinfoNSFW {
  sid: string;
  passwd: string;
  nsfw: boolean;
}

async function apiCustomerinfoNSFW(sid: string, nsfw: boolean): Promise<void> {
  const data: CustomerinfoNSFW = {
    sid: sid,
    passwd: config.passwd,
    nsfw: nsfw,
  };

  try {
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.post('/api/customerinfo-nsfw', data);
    if (response.status !== 200) {
      console.warn('Error during POST', response.status);
    }
  } catch (error) {
    console.error('Error during POST request:', error);
  }
}

// interface Customerinfopatreonid {
//   sid: string;
//   passwd: string;
//   patreonid: string;
// }

// async function apiCustomerinfopatreonid(sid: string, patreonid: string): Promise<void> {
//   const data: Customerinfopatreonid = {
//     sid: sid,
//     passwd: config.passwd,
//     patreonid: patreonid,
//   };

//   try {
//     const axiosInstance = await createAxiosInstance();
//     const response = await axiosInstance.post('/api/customerinfo-patreonid', data);
//     if (response.status !== 200) {
//       console.warn('Error during POST', response.status);
//     }
//   } catch (error) {
//     console.error('Error during POST request:', error);
//   }
// }

interface CustomerinfoActivate {
  sid: string;
  passwd: string;
  activate: number;
}

async function apiCustomerinfoActivate(sid: string, activate: number): Promise<void> {
  const data: CustomerinfoActivate = {
    sid: sid,
    passwd: config.passwd,
    activate: activate,
  };

  try {
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.post('/api/customerinfo-activate', data);
    if (response.status !== 200) {
      console.warn('Error during POST', response.status);
    }
  } catch (error) {
    console.error('Error during POST request:', error);
  }
}


export {
  apiCustomerinfoGetmeta,
  apiCustomerinfoGetstream,
  apiCustomerinfoNSFW,
  // apiCustomerinfopatreonid,
  apiCustomerinfoActivate,
};
