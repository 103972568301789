import axios, { AxiosInstance } from "axios";

interface Config {
    baseurl: string;
    contentType: string;
    passwd: string;
}

const development: Config = {
    baseurl: 'http://localhost:15000',
    contentType: 'application/json',
    passwd: 'boldsignal!'
};

const production: Config = {
    // baseurl: 'https://fusio.net:15000',
    baseurl: 'https://api.fusioai.net',
    contentType: 'application/json',
    passwd: 'boldsignal!'
};

export const config: Config = process.env.NODE_ENV === 'production' ? production : development;

export const bubbleUrl: string = 'https://landing.fusioai.net/version-test'


export async function createAxiosInstance(): Promise<AxiosInstance> {
    return axios.create({
        baseURL: config.baseurl,
        headers: {
            'Content-Type': config.contentType,
        },
    });
}


