// React 및 주요 라이브러리
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

// MUI 관련 컴포넌트와 스타일링
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

// 전역 상태 관리 (Recoil Atoms)
import {
  ChatMessage,
  ChatInfo,
  isAppOpen,
} from '../recoilAtoms';

// 로컬 스타일 및 이미지 파일
import { styles } from './chatbox.style';
import stream_setup from '@common/assets/image/stream_setup.svg';

// 설정 파일
import { config } from '@services/api/baseapi';
import { useRecoilState } from 'recoil';

interface ChatstreamProps {
  chatInfo: ChatInfo;
  sdmodel: string;
  modelNames: string[];
  messages: ChatMessage[];
  waitGenImgButton: boolean[];
  chatContainerRef: React.RefObject<HTMLDivElement>
  handleScroll: () => void;
  divImageHeight: number;
  setSdmode: (sdmodel: string) => Promise<void>;
  handleGenerateImage: (index: number, idx: number, text: string) => Promise<void>;
  messagesEndRef: React.RefObject<HTMLDivElement>
}

const ChatBox: React.FC<ChatstreamProps> = ({
  chatInfo,
  sdmodel,
  modelNames,
  messages,
  waitGenImgButton,
  chatContainerRef,
  handleScroll,
  divImageHeight,
  setSdmode,
  handleGenerateImage,
  messagesEndRef,
}) => {
  const classes = makeStyles(styles)();

  // Apperance
  const [appOpen, setAppOpen] = useRecoilState(isAppOpen);
  const handleAppClose = () => {
    setAppOpen(false);
  }

  const handleAppOpen = () => {
    setAppOpen(true);
  }

  const [sexSelected, setSexSelected] = useState('Please complete the information');
  const [ageSelected, setAgeSelected] = useState('Please complete the information');
  const [featureSelected, setFeatureSelected] = useState('Please complete the information');

  useEffect(() => {
    if (chatInfo.appearance !== "") {
      const parsedLlmres: Record<string, string> = JSON.parse(chatInfo.appearance);
      setSexSelected(parsedLlmres["sex"] && parsedLlmres["sex"] !== "None" ? parsedLlmres["sex"] : "");
      setAgeSelected(parsedLlmres["age"] && parsedLlmres["age"] !== "None" ? parsedLlmres["age"] : "");
      setFeatureSelected(
        [
          parsedLlmres["special"],
          parsedLlmres["ethnicity"],
          parsedLlmres["body"],
          parsedLlmres["face1"],
          parsedLlmres["face2"],
          parsedLlmres["hairstyle"],
          parsedLlmres["haircolor"],
          parsedLlmres["breast"],
          parsedLlmres["ass"],
        ]
          .filter(value => value && value !== "None")
          .join(", ")
      );

    }
  }, [chatInfo]);




  return (
    <Box
      className={classes.div_message}
      ref={chatContainerRef}
      onScroll={handleScroll}
      style={{ height: `calc(100% - ${divImageHeight}px)` }}
    >
      <Box className={classes.message_init}>
        <Box className={classes.message_init_top}>
          <Box className={classes.message_character_img}>
            <Box className={classes.message_character_img_box}>
              <img
                src={`${config.baseurl}/api/images/${chatInfo.img}`}
                loading="lazy"
                style={{ maxHeight: '80px', maxWidth: '80px' }}
              />
            </Box>
            <Typography
              style={{ fontSize: '24', fontWeight: '900', marginTop: '10px' }}
            > {`${chatInfo.charactername}`} </Typography>
          </Box>
          <Box className={classes.message_character_info}>
            <Typography className={classes.message_character_info_txt}>
              Sex: {sexSelected}<br />
              Age: {ageSelected}<br />
              Features: {featureSelected}<br />
            </Typography>
          </Box>
        </Box>
        <Box className={classes.message_init_bottom}>
          <Typography
            className={classes.message_bottom_desc}
          > Please set up the appearance of your character for better image generation. </Typography>
          <Box className={classes.message_bottom_set} onClick={handleAppOpen}>
            <img
              src={stream_setup}
              loading="lazy"
              style={{
                marginRight: '8px',
                marginLeft: '8px',
                height: '24px',
                width: '24px',
              }}
            />
            <Typography className={classes.div_header_set}>Set Appearance</Typography>
          </Box>
        </Box>
      </Box>
      {messages.map((msg, index) => (
        <Box key={index} className={classes.message_wrap}>
          <Box className={classes.message_img_left}>
            <img
              src={msg.ischaracter ? `${config.baseurl}/api/images/${chatInfo.img}` : undefined}
              loading="lazy"
              style={{ maxHeight: '48px', maxWidth: '48px' }}
            />
          </Box>
          <Box key={index} className={classes.message_main}>
            <Typography> {msg.ischaracter ? `${chatInfo.charactername}` : 'YOU'} </Typography>
            <Box key={index} className={classes.message_content}>
              <Typography className={classes.message_txt}>{msg.text}</Typography>
              <Box key={index} className={classes.message_img_box} >
                {waitGenImgButton[index] ? (
                  <Typography className={classes.message_genbutton_gen}>Visualizing</Typography>
                ) : (
                  <Typography
                    className={classes.message_genbutton}
                    onClick={() => handleGenerateImage(index, msg.idx, msg.text)}
                  >Visualizing</Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      ))}
      <div ref={messagesEndRef} />
    </Box>

  );
};

export { ChatBox };
