import { config } from '@services/api/baseapi';

async function sendSidToBackground(sid: string) {
    if (sid) {
        await chrome.runtime.sendMessage({
            action: "save_sid",
            payload: { key: sid }
        });
    }
}

async function sendServerPathToBackground() {
    await chrome.runtime.sendMessage({
        action: "save_server_path",
        payload: { key: config.baseurl }
    });
}


const isChromeExtension = () => {
    return typeof chrome !== 'undefined' && !!chrome.storage;
};

const getStorage = async (keys: string[]): Promise<Record<string, string | null>> => {
    if (isChromeExtension()) {
        return new Promise<Record<string, string | null>>((resolve) => {
            chrome.storage.local.get(keys, (result) => {
                const resolvedResult = keys.reduce((acc, key) => {
                    acc[key] = result[key] || 'None';
                    return acc;
                }, {} as Record<string, string | null>);
                resolve(resolvedResult);
            });
        });
    } else {
        const result: Record<string, string | null> = {};
        keys.forEach((key) => {
            result[key] = localStorage.getItem(key) || 'None';
        });
        return result;
    }
};

const setStorage = async (data: Record<string, any>): Promise<void> => {
    if (isChromeExtension()) {
        return new Promise<void>((resolve) => {
            chrome.storage.local.set(data, () => resolve());
        });
    } else {
        Object.entries(data).forEach(([key, value]) => {
            // 객체 또는 다른 타입의 값은 JSON으로 변환
            const storedValue = typeof value === 'string' ? value : JSON.stringify(value);
            localStorage.setItem(key, storedValue);
            window.dispatchEvent(
                new StorageEvent('storage', {
                    key: key, // sid 또는 server_url
                    newValue: storedValue,
                })
            );
        });
    }
};

export { sendSidToBackground, sendServerPathToBackground, isChromeExtension, getStorage, setStorage }


