// React 및 주요 라이브러리
import React, { useState } from 'react';

// MUI 관련 컴포넌트와 스타일링
import { Box, Button, Modal, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

// 로컬 스타일 및 이미지 파일
import { styles } from './resultmodal.style';
import green_check from '@common/assets/image/green_check.svg';
import warning_triangle from '@common/assets/image/warning_triangle.svg';
import close_white from '@common/assets/image/close_white.svg';


type ResultmodalProps = {
  resultmodalOpen: boolean;
  result: string;
  handleModalClose: () => void;
};

const Resultmodal: React.FC<ResultmodalProps> = ({
  resultmodalOpen,
  result,
  handleModalClose,
}) => {
  const classes = makeStyles(styles)();

  return (

    <Modal open={resultmodalOpen} onClose={handleModalClose}>
      <Box
        className={classes.modalOverlay}
        onClick={handleModalClose}
      >
        {(result === 'success') ? (
          <Box
            className={classes.modalContent}
            onClick={(e) => e.stopPropagation()}
          >
            <Box
              className={classes.closebox}
            >
              <Box
                className={classes.imgwrap}
                onClick={handleModalClose}
              >
                <img
                  src={close_white}
                  style={{ maxHeight: '32px', maxWidth: '32px' }}
                />
              </Box>

            </Box>
            <Box
              className={classes.imgwrap_withmarginbottom}
            >
              <img
                src={green_check}
                style={{ maxHeight: '48px', maxWidth: '48px' }}
              />
            </Box>
            <Typography className={classes.modalTitle}>Patreon Connected!</Typography>
            <Box className={classes.buttonBox}>
            </Box>
          </Box>
        ) : (
          <Box
            className={classes.modalContent}
            onClick={(e) => e.stopPropagation()}
          >
            <Box
              className={classes.closebox}
            >
              <Box
                className={classes.imgwrap}
                onClick={handleModalClose}
              >
                <img
                  src={close_white}
                  style={{ maxHeight: '32px', maxWidth: '32px' }}
                />
              </Box>

            </Box>
            <Box
              className={classes.imgwrap}
            >
              <img
                src={warning_triangle}
                style={{ maxHeight: '48px', maxWidth: '48px' }}
              />
            </Box>
            <Typography className={classes.modalTitle}>Your Patreon ID </Typography>
            <Typography className={classes.modalTitle}>Already connected with Fusioai</Typography>
            <Typography className={classes.modalTitle}>Fusioai email: {result}</Typography>
            <Box className={classes.buttonBox}>

            </Box>
          </Box>
        )}

      </Box>
    </Modal>
  );
};

export { Resultmodal };
