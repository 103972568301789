import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { styles } from './test.style';
import { apiTestTxt2Img, apiTestImg2Img, apiTestItterogate } from '@services/api/testimggen';
import { apiTestLLMNovita1, apiTestLLMNovita2, apiTestLLMOpenai } from '@services/api/testllm';
import { useRecoilState } from 'recoil';
import { clientInfoAtom, pageAtom } from '../recoilAtoms';
import { config } from '@services/api/baseapi';

const useStyles = makeStyles(styles);

const Test: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [page, setPage] = useRecoilState(pageAtom);

    useEffect(() => {
        setPage("/test");
    }, []);

    const [imgGenInput, setImgGenInput] = useState("");
    const [imgUrl, setImgUrl] = useState("");

    const [img2ImgInput, setImg2ImgInput] = useState("");
    const [img2ImgUrl, setImg2ImgUrl] = useState("");

    const [itterogateUrl, setItterogateUrl] = useState("");
    const [ittergateText, setItterogateText] = useState("");

    const [promptFirst, setPromptFirst] = useState("");
    const [textFirst, setTextFirst] = useState("");
    const [msgFirst, setMsgFirst] = useState("");

    const [promptFirst2, setPromptFirst2] = useState("");
    const [textFirst2, setTextFirst2] = useState("");
    const [msgFirst2, setMsgFirst2] = useState("");

    const [promptSecond, setPromptSecond] = useState("");
    const [textSecond, setTextSecond] = useState("");
    const [msgSecond, setMsgSecond] = useState("");

    const handleImgGenInput = (e: { target: { value: any; }; }) => {
        const value = e.target.value;
        try {
            setImgGenInput(value);
        } catch (error) {
            console.error(error);
        }
    };

    const handleImg2ImgInput = (e: { target: { value: any; }; }) => {
        const value = e.target.value;
        try {
            setImg2ImgInput(value);
        } catch (error) {
            console.error(error);
        }
    };

    const handleItterogateInput = (e: { target: { value: any; }; }) => {
        const value = e.target.value;
        try {
            setItterogateUrl(value);
        } catch (error) {
            console.error(error);
        }
    };

    const handlePrpmptFirst = (e: { target: { value: any; }; }) => {
        const value = e.target.value;
        try {
            setPromptFirst(value);
        } catch (error) {
            console.error(error);
        }
    };

    const handlePrpmptFirst2 = (e: { target: { value: any; }; }) => {
        const value = e.target.value;
        try {
            setPromptFirst2(value);
        } catch (error) {
            console.error(error);
        }
    };

    const handleTextFirst = (e: { target: { value: any; }; }) => {
        const value = e.target.value;
        try {
            setTextFirst(value);
        } catch (error) {
            console.error(error);
        }
    };

    const handleTextFirst2 = (e: { target: { value: any; }; }) => {
        const value = e.target.value;
        try {
            setTextFirst2(value);
        } catch (error) {
            console.error(error);
        }
    };

    const handlePrpmptSecond = (e: { target: { value: any; }; }) => {
        const value = e.target.value;
        try {
            setPromptSecond(value);
        } catch (error) {
            console.error(error);
        }
    };

    const handleTextSecond = (e: { target: { value: any; }; }) => {
        const value = e.target.value;
        try {
            setTextSecond(value);
        } catch (error) {
            console.error(error);
        }
    };

    const buttonImgGen = async () => {
        const url = await apiTestTxt2Img(imgGenInput)
        setImgUrl(`${config.baseurl}/api/images/${url || ""}`)
        console.log(url)
    };

    const buttonImg2Img = async () => {
        const url = await apiTestImg2Img(img2ImgInput)
        setImg2ImgUrl(`${config.baseurl}/api/images/${url || ""}`)
        console.log(url)
    };

    const buttonItterogate = async () => {
        const text = await apiTestItterogate(itterogateUrl)
        setItterogateText(text || '')
        console.log(text)
    };


    const buttonFirst = async () => {
        const msg_response = await apiTestLLMNovita1(promptFirst, textFirst)
        setMsgFirst(msg_response || "")
        console.log(msg_response)
    };

    const buttonFirst2 = async () => {
        const msg_response = await apiTestLLMNovita2(promptFirst2, textFirst2)
        setMsgFirst2(msg_response || "")
        console.log(msg_response)
    };


    const buttonSecond = async () => {
        const msg_response = await apiTestLLMOpenai(promptFirst, textSecond)
        setMsgSecond(msg_response || "")
        console.log(msg_response)
    };


    return (
        <Box className={classes.root}>
            <Typography className={classes.title}>Txt2img</Typography>
            <Box className={classes.first_div}>
                <Box className={classes.imggenTextFieldWrap}>
                    <TextField
                        value={imgGenInput}
                        onChange={handleImgGenInput}
                        multiline
                        className={classes.imggenTextField}
                    />

                </Box>
                <Button
                    className={classes.imggenButton}
                    onClick={buttonImgGen}
                >
                    전송
                </Button>
                <Box className={classes.imggenBox}>
                    <img
                        src={imgUrl}
                        style={{
                            height: 'auto',
                            maxHeight: '400px',
                            width: 'auto',
                            maxWidth: '400px',
                        }}
                    />
                </Box>
            </Box>
            <Typography className={classes.title}>Img2img</Typography>
            <Box className={classes.first_div}>
                <Box className={classes.imggenTextFieldWrap}>
                    <TextField
                        value={img2ImgInput}
                        onChange={handleImg2ImgInput}
                        multiline
                        className={classes.imggenTextField}
                    />

                </Box>
                <Button
                    className={classes.imggenButton}
                    onClick={buttonImg2Img}
                >
                    전송
                </Button>
                <Box className={classes.imggenBox}>
                    <img
                        src={img2ImgUrl}
                        style={{
                            height: 'auto',
                            maxHeight: '400px',
                            width: 'auto',
                            maxWidth: '400px',
                        }}
                    />
                </Box>
            </Box>
            <Typography className={classes.title}>Img2txt</Typography>
            <Box className={classes.first_div}>
                <Box className={classes.imggenTextFieldWrap}>
                    <TextField
                        value={itterogateUrl}
                        onChange={handleItterogateInput}
                        multiline
                        className={classes.imggenTextField}
                    />

                </Box>
                <Button
                    className={classes.imggenButton}
                    onClick={buttonItterogate}
                >
                    전송
                </Button>
                <Box className={classes.imggenBox}>
                    <Typography className={classes.msg_response}>
                        {ittergateText}
                    </Typography>
                </Box>
            </Box>
            <Typography className={classes.title}>Chat Novita 70B</Typography>
            <Box className={classes.second_div}>
                <Box className={classes.imggenTextFieldWrap}>
                    <Box className={classes.imggenTextFieldWrapElement}>
                        <Typography className={classes.subtitle}>System</Typography>
                        <TextField
                            value={promptFirst}
                            onChange={handlePrpmptFirst}
                            multiline
                            className={classes.promptTextField}
                        />
                    </Box>
                    <Box className={classes.imggenTextFieldWrapElement}>
                        <Typography className={classes.subtitle}>Text</Typography>
                        <TextField
                            value={textFirst}
                            onChange={handleTextFirst}
                            multiline
                            className={classes.promptTextField}
                        />
                    </Box>
                </Box>
                <Button
                    className={classes.imggenButton}
                    onClick={buttonFirst}
                >
                    전송
                </Button>
                <Box className={classes.imggenBox}>
                    <Typography className={classes.msg_response}>
                        {msgFirst}
                    </Typography>
                </Box>
            </Box>
            <Typography className={classes.title}>Chat Novita 8B</Typography>
            <Box className={classes.second_div}>
                <Box className={classes.imggenTextFieldWrap}>
                    <Box className={classes.imggenTextFieldWrapElement}>
                        <Typography className={classes.subtitle}>System</Typography>
                        <TextField
                            value={promptFirst2}
                            onChange={handlePrpmptFirst2}
                            multiline
                            className={classes.promptTextField}
                        />
                    </Box>
                    <Box className={classes.imggenTextFieldWrapElement}>
                        <Typography className={classes.subtitle}>Text</Typography>
                        <TextField
                            value={textFirst2}
                            onChange={handleTextFirst2}
                            multiline
                            className={classes.promptTextField}
                        />
                    </Box>
                </Box>
                <Button
                    className={classes.imggenButton}
                    onClick={buttonFirst2}
                >
                    전송
                </Button>
                <Box className={classes.imggenBox}>
                    <Typography className={classes.msg_response}>
                        {msgFirst2}
                    </Typography>
                </Box>
            </Box>
            <Typography className={classes.title}>Chat OpenAI4o</Typography>
            <Box className={classes.last_div}>
                <Box className={classes.imggenTextFieldWrap}>
                    <Box className={classes.imggenTextFieldWrapElement}>
                        <Typography className={classes.subtitle}>System</Typography>
                        <TextField
                            value={promptSecond}
                            onChange={handlePrpmptSecond}
                            multiline
                            className={classes.promptTextField} // 스타일 클래스 적용
                        />
                    </Box>
                    <Box className={classes.imggenTextFieldWrapElement}>
                        <Typography className={classes.subtitle}>Text</Typography>
                        <TextField
                            value={textSecond}
                            onChange={handleTextSecond}
                            multiline
                            className={classes.promptTextField} // 스타일 클래스 적용
                        />
                    </Box>
                </Box>
                <Button
                    className={classes.imggenButton}
                    onClick={buttonSecond}
                >
                    전송
                </Button>
                <Box className={classes.imggenBox}>
                    <Typography className={classes.msg_response}>
                        {msgSecond}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};



export { Test };
