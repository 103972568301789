import { createAxiosInstance, config } from './baseapi';


interface TestImgGenInterface {
  params: string
  passwd: string
}

interface TestImgGenResponse {
  image_url: string;
}

async function apiTestTxt2Img(params: string): Promise<string | null> {
  const data: TestImgGenInterface = {
    params: params,
    passwd: config.passwd
  };

  try {
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.post<TestImgGenResponse>('/api/test-txt2img', data);
    return response.data.image_url;
  } catch (error) {
    console.error('Error during POST request:', error);
    return null;
  }
}


async function apiTestImg2Img(params: string): Promise<string | null> {
  const data: TestImgGenInterface = {
    params: params,
    passwd: config.passwd
  };

  try {
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.post<TestImgGenResponse>('/api/test-img2img', data);
    return response.data.image_url;
  } catch (error) {
    console.error('Error during POST request:', error);
    return null;
  }
}

interface TestItterogateInterface {
  imageurl: string
  passwd: string
}

interface TestItterogateResponse {
  text: string;
}

async function apiTestItterogate(imageurl: string): Promise<string | null> {
  const data: TestItterogateInterface = {
    imageurl: imageurl,
    passwd: config.passwd
  };

  try {
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.post<TestItterogateResponse>('/api/test-itterogate', data);
    return response.data.text;
  } catch (error) {
    console.error('Error during POST request:', error);
    return null;
  }
}

export { apiTestTxt2Img, apiTestImg2Img, apiTestItterogate };
