import { createStyles } from '@mui/styles';

import { WessistTheme } from '@common/style/themeSelector';

export const styles = (theme: WessistTheme) =>
  createStyles({
    root: {
      margin: '0px 0px 0px 0px',
      width: '100%',
      height: '72px',
      padding: '0px',
      borderBottom: '1px solid #8E8E93',
      backgroundColor: theme.Wcolor.black,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    title: {
      width: '154px',
      height: '50px',
      marginLeft: '40px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title_img: {
      width: '154px',
      height: '40px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title_txt: {
      width: '200px',
      height: '50px',
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      color: theme.Wcolor.white,
    },
    info: {
      width: 'auto',
      height: '50px',
      padding: '0px 40px 0px 0px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'end',
      alignItems: 'center',
    },
    info_token: {
      width: '154px',
      height: '40px',
      marginRight: '0px',
      padding: '5px 10px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: '1px solid #D84E98',
      borderRadius: '12px'
    },
    info_txt2: {
      width: 'auto',
      height: '40px',
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      color: theme.Wcolor.white,
      fontSize: 18,
      whiteSpace: 'nowrap',
    },
  });
