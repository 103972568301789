import React, { useEffect } from 'react';
import { useLocation, useNavigate, Navigate, Route, Routes } from 'react-router-dom';

import {
  ChatStream,
  ChatList,
  SharelistPage,
  ChatUI,
  GalleryPage,
  ProfilePage,
  SubscribePage,
  TestPage,
  AdminPage,
  LoginPage,
  FeedbackPage,
  SharePage,
} from '@renderer/fusio/intro';

import { setStorage } from '@services/extension/funcs';
import { config } from '@services/api/baseapi';

const textCrollSiteSettings = {
  "janitorai.com": {
    urlPattern: "^https://janitorai\\.com/chats/\\d+$",
    titleSelector: "div.css-1crfvwd",
    textSelector: "div.css-ijnpk1",
    speakerSelector: "div.css-1c2za90",
    imageSelector: "div.css-uul55m img",
  },
  "character.ai": {
    urlPattern: "^https://character\\.ai/chat/[-\\w]+$",
    titleSelector: "p.font-semi-bold.line-clamp-1.text-ellipsis.break-anywhere.overflow-hidden.whitespace-normal",
    textSelector: "div.flex.w-full.flex-col.justify-start.h-full.overflow-y-scroll.overflow-x-hidden.align-middle div.font-display.font-light.swiper-no-swiping",
    speakerSelector: "div.flex.w-full.flex-col.justify-start.h-full.overflow-y-scroll.overflow-x-hidden.align-middle div.text-sm:not(.rounded-2xl):not(.bg-secondary):not(.px-2):not(.font-light):not(.h-fit):not(.text-muted-foreground):not(.font-normal):not(.truncate):not(.font-semi-bold):not(.whitespace-normal):not(.bg-secondary)",
    imageSelector: 'div.flex.gap-3 img',
  },
  "spicychat.ai": {
    urlPattern: "^https://spicychat\\.ai/chat/[-\\w]+(?:/[-\\w]+)*$",
    titleSelector: "title",
    textSelector: "div.flex.flex-col.pl-\\[6px\\].grow",
    speakerSelector: "div.flex.justify-undefined.items-undefined.w-full.justify-between",
    imageSelector: "div.flex.flex-col.justify-undefined.items-undefined.w-max.ml-\\[4px\\].mt-\\[5px\\] img",
  },
  "crushon.ai": {
    urlPattern: "^https://crushon\\.ai(?:/[^/]+)?/character(?:/[-\\w]+)?/chat$",
    titleSelector: "title",
    textSelector: "div.flex.flex-col.gap-3.self-stretch.w-fit, div.double-click-filter.rounded-lg.rounded-tr-sm.bg-blue-3.p-2\\.5",
    speakerSelector: "a.truncate.text-sm:not(.font-bold):not(.text-black):not(.dark\\:text-white), div.mt-4.flex.min-w-0.flex-1.flex-col.items-end.gap-2",
    imageSelector: "a.double-click-filter.inline-block.overflow-hidden.rounded-full img",
  },
};

export const AppRoute: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sid = params.get('sid');

    setStorage({ 'fusio_textCrollSiteSettings': textCrollSiteSettings })

    if (sid) {
      setStorage({ 'fusio_sid': sid });
      setStorage({ 'fusio_server_url': config.baseurl });
      if (sid === "None") {
        navigate('/login')
      } else {
        window.location.href = `${location.pathname}`
      }
    }
  }, [location, navigate]);

  return (
    <Routes>
      <Route index element={<ChatStream />} />
      <Route path="*" element={<Navigate to={'/'} />} />
      <Route path="/streaming" element={<ChatStream />} />
      <Route path="/share" element={<SharePage />} />
      <Route path="/chatlist" element={<ChatList />} />
      <Route path="/sharelist" element={<SharelistPage />} />
      <Route path="/chat" element={<ChatUI />} />
      <Route path="/gallery" element={<GalleryPage />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/subscribe" element={<SubscribePage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/feedback" element={<FeedbackPage />} />
      <Route path="/doublem_test" element={<TestPage />} />
      <Route path="/doublem_admin" element={<AdminPage />} />
    </Routes>
  );
};
