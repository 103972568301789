import { createAxiosInstance, config } from './baseapi';


async function apiSdmodellist(): Promise<string[] | null> {
  try {
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.get<string[]>('/api/sd-model');
    return response.data;
  } catch (error) {
    console.error('Error during POST request:', error);
    return null;
  }
}

export { apiSdmodellist };
