import { createAxiosInstance, config } from './baseapi';

interface hosterlog {
  sid: string;
  event: string;
  realm: string;
  etc: string;
}


async function apiHosterlog(sid: string, event: string, realm: string, etc: string): Promise<void> {
  const data: hosterlog = {
    sid: sid,
    event: event,
    realm: realm,
    etc: etc,
  };

  const axiosInstance = await createAxiosInstance();
  await axiosInstance.post('/api/hoster-log', data);
}


function Logging(sid: string, event: string, realm: string, etc: string) {
  const fetchLogging = async () => {
    await apiHosterlog(sid, event, realm, etc)
  };
  fetchLogging()
}

export { Logging };


