import { getStorage } from '@services/extension/funcs';
import { createAxiosInstance, config } from './baseapi';

interface patreonmeta {
  item: string;
  passwd: string;
}

interface patreonmetaResponse {
  result: string;
}

async function apiPatreonmeta(item: string): Promise<string | null> {
  const data: patreonmeta = {
    item: item,
    passwd: config.passwd,
  };

  try {
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.post<patreonmetaResponse>('/api/patreonmeta', data);
    return response.data.result;
  } catch (error) {
    console.error('Error during POST request:', error);
    return null;
  }
}

interface patreonoauth {
  sid: string
  code: string;
  passwd: string;
}

interface patreonoauthResponse {
  result: string;
}

async function apiPatreonoauth(code: string): Promise<string | null> {
  const result = await getStorage(['fusio_sid']);
  const currentSid = result.fusio_sid || 'None';
  const data: patreonoauth = {
    sid: currentSid,
    code: code,
    passwd: config.passwd,
  };

  try {
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.post<patreonoauthResponse>('/api/patreonoauth', data);
    return response.data.result;
  } catch (error) {
    console.error('Error during POST request:', error);
    return null;
  }
}

interface patreondisconnect {
  sid: string
  passwd: string;
}

async function apiPatreondisconnect(): Promise<void> {
  const result = await getStorage(['fusio_sid']);
  const currentSid = result.fusio_sid || 'None';
  const data: patreondisconnect = {
    sid: currentSid,
    passwd: config.passwd,
  };

  try {
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.post<void>('/api/patreondisconnect', data);
    if (response.status !== 200) {
      console.warn('Error during POST', response.status);
    }
  } catch (error) {
    console.error('Error during POST request:', error);
  }
}


export { apiPatreonmeta, apiPatreonoauth, apiPatreondisconnect };


