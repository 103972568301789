import { createAxiosInstance, config } from './baseapi';


interface FeedbackData {
  sid: string;
  content: string;
  passwd: string;
}


async function apiFeedback(sid: string, content: string): Promise<void> {
  const data: FeedbackData = {
    sid: sid,
    content: content,
    passwd: config.passwd,
  };
  try {
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.post('/api/feedback', data);
    if (response.status !== 200) {
      console.warn('Error during POST', response.status);
    }
  } catch (error) {
    console.error('Error during POST request:', error);
  }
}

export { apiFeedback };