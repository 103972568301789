import { createAxiosInstance, config } from './baseapi';

interface sharedcharacter {
  passwd: string;
}



interface sharedcharacterResponse {
  charactername: string;
  domain: string;
  img: string;
}

async function apisharedcharacter(): Promise<sharedcharacterResponse[] | null> {
  const data: sharedcharacter = {
    passwd: config.passwd,
  };

  try {
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.post('/api/sharedcharacter-getmeta', data);
    return response.data;
  } catch (error) {
    console.error('Error during POST request:', error);
    return null;
  }
}


export {
  apisharedcharacter,
  sharedcharacterResponse,
};


