import { createStyles } from '@mui/styles';

import { WessistTheme } from '@common/style/themeSelector';

export const styles = (theme: WessistTheme) =>
  createStyles({
    div_first_root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px',
    },
    div_first: {
      width: '100%',
      maxWidth: '517px',
      height: '510px',
      backgroundColor: '#252525',
      border: '1px solid #8d8d8d',
      borderRadius: '20px',
      padding: '12px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title_box: {
      width: '100%',
      height: '40px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '12px 0px 0px 0px',
    },
    title: {
      fontSize: '36px',
      fontWeight: '700',
      color: theme.Wcolor.white,
    },
    text_box: {
      width: `calc(100%px)`,
      height: '40px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0px 0px 0px 0px',
    },
    text: {
      fontSize: '20px',
      color: theme.Wcolor.white,
    },
    info_box: {
      width: '100%',
      height: `calc(100% - 140px)`,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',

    },
    modalTextField: {
      width: '100%',
      height: '100%',
      '& .MuiOutlinedInput-root': {
        height: '100%', // Ensure full height
        '& textarea': {
          height: '100% !important', // Force full height for multiline input
          cursor: 'text', // Show text cursor over entire area
        },
        '& fieldset': {
          border: '1px solid white',
          borderRadius: 4,
        },
        '&:hover fieldset': {
          border: '1px solid white',
        },
        '&.Mui-focused fieldset': {
          border: '1px solid white',
        },
      },
      '& .MuiInputBase-root': {
        color: 'white',
      },
    },
    button_box: {
      width: '100%',
      height: '40px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '10px 0px 10px 0px',
    },
    button: {
      width: '150px',
      height: '40px',
      fontSize: '20px',
      borderRadius: '12px',
      backgroundColor: theme.Wcolor.pink2,
      color: theme.Wcolor.white,
    },
  });
