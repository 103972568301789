// 외부 라이브러리
import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Typography, Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useRecoilState } from 'recoil';
import { saveAs } from 'file-saver';
import clsx from 'clsx';

// API 및 서비스 관련 모듈
import { apiChatlistGetmeta, ChatlistResponse } from '@services/api/chatlist';
import { apiImageitemGetmeta, ImageitemResponse } from '@services/api/imageitem';
import { config } from '@services/api/baseapi';
import { Logging } from '@services/api/hosterlog';

// 상태 및 Atom 관리 관련 모듈
import { ChatImage, clientInfoAtom, navbarCollapsed, pageAtom } from '../recoilAtoms';

// 스타일 관련 모듈
import { styles } from './gallery.style';
import { Nostream } from '../nostream/nostream';

const useStyles = makeStyles(styles);

const Gallery: React.FC = () => {
    const classes = useStyles();
    const [clientInfo, setClientInfo] = useRecoilState(clientInfoAtom);
    const [chatList, setChatList] = useState<ChatlistResponse[]>([]);
    const [isCollapsed, setIsCollapsed] = useRecoilState(navbarCollapsed);
    const [_, setPage] = useRecoilState(pageAtom);

    const [selectedChatid, setSelectedChatid] = useState("");
    const [loading, setLoading] = useState(true);
    const [images, setImages] = useState<ChatImage[]>([]);
    const [imageExists, setImageExists] = useState(true);
    const [isImageloading, setIsImageLoading] = useState(false);
    const [modalOpen, setMedalOpen] = useState(false);
    const [showButtons, setShowButtons] = useState(true); // 버튼 표시 상태

    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [selectedContent, setSelectedContent] = useState<string | null>(null);
    const [selectedPrompt, setSelectedPrompt] = useState<string | null>(null);

    useEffect(() => {
        setPage("/gallery");
        if (clientInfo.sid !== 'none') {
            Logging(clientInfo.sid, 'visit', 'gallery', 'web')
        }

        const fetchCustomerInfo = async () => {
            try {
                if (clientInfo.sid && clientInfo.sid !== "none") {
                    const customerinfo = await apiChatlistGetmeta(clientInfo.sid);
                    if (Array.isArray(customerinfo)) {
                        setChatList(customerinfo.filter(info => info.sid && info.img));
                    }
                }
            } catch (error) {
                console.error("Error fetching chatlist:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchCustomerInfo();

        return () => {
            setChatList([]);
        };
    }, [clientInfo.sid]);

    const handleSetSelectedChatid = (chatid: string) => {
        setIsImageLoading(true);
        setSelectedChatid(chatid);
        const fetchGallery = async () => {
            const imageItems = await apiImageitemGetmeta(clientInfo.sid, chatid);
            if (imageItems && imageItems.length > 0) {
                const newImages = imageItems.map((item: ImageitemResponse) => ({
                    id: item.id,
                    content: item.content,
                    prompt: item.prompt,
                    llmres: item.llmres,
                    idx: item.idx,
                    msgid: item.msgid,
                    imageUrl: item.img !== "" ? `${config.baseurl}/api/images/${clientInfo.sid}/${chatid}/${item.img}` : undefined,
                })).sort((a, b) => b.id - a.id);
                setImages(newImages);
                setImageExists(true);
            } else {
                setImages([]);
                setImageExists(false)
            }
        }
        fetchGallery();
        setTimeout(() => {
            setIsImageLoading(false);
            // setIsCollapsed(true)
        }, 1000);

    };


    useEffect(() => {
        if (!isCollapsed && !showButtons) {
            const timer = setTimeout(() => {
                setShowButtons(true);
            }, 1000);

            return () => clearTimeout(timer);
        }
        if (isCollapsed) {
            setShowButtons(false);
        }
    }, [isCollapsed]);

    const handleOpen = (imageUrl: string, content: string, prompt: string) => {
        setSelectedImage(imageUrl); // 선택된 이미지를 저장
        setSelectedContent(content);
        setSelectedPrompt(prompt);
        setMedalOpen(true); // 모달 열림
    };

    const handleClose = () => {
        setMedalOpen(false); // 모달 닫힘
        setSelectedImage(null); // 선택된 이미지 초기화
    };

    const handleDownload = async (imageUrl: string) => {
        try {
            // 이미지 파일을 가져오기
            const response = await fetch(imageUrl);
            const blob = await response.blob(); // Blob 객체로 변환
            saveAs(blob, `fusio_${selectedChatid}_${new Date().getTime()}.jpg`); // FileSaver.js를 사용하여 기기에 저장
        } catch (error) {
            console.error('Error downloading the image:', error);
        }
    };

    return (
        <Box className={classes.root}>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress size={44} style={{ color: '#D84E98' }} />
                </Box>
            ) : (
                <Box className={classes.root_main}>
                    <Box
                        className={classes.chatid_list}
                        sx={{
                            width: isCollapsed ? '0px' : '200px',
                            transition: 'width 1.0s ease',
                            borderRight: isCollapsed ? 'none' : '1px solid #ddd',
                        }}
                    >
                        {showButtons && chatList.map((chatInfo, index) => (
                            <Box
                                key={chatInfo.chatid}
                                className={classes.chat_list_item_box}
                            >
                                <Typography
                                    className={clsx(classes.chat_list_item, {
                                        [classes.buttonSelected]: selectedChatid === chatInfo.chatid,
                                    })}
                                    key={index}
                                    onClick={() => handleSetSelectedChatid(chatInfo.chatid)}
                                >
                                    {chatInfo.charactername}
                                </Typography>
                            </Box>
                        ))}
                    </Box>

                    {isImageloading ? (
                        <Box className={classes.gallery_main_circular} >
                            <CircularProgress size={44} style={{ color: '#D84E98' }} />
                        </Box>
                    ) : (
                        <Box
                            className={classes.gallery_main}
                            sx={{
                                width: isCollapsed ? '100%' : `calc(100% - 200px)`,
                                transition: 'width 1.0s ease',
                            }}
                        >
                            {imageExists ? (
                                <Box className={classes.gallery_wrap}>
                                    {images.map(
                                        (msg, index) =>
                                            msg.imageUrl && (
                                                <Box
                                                    key={index}
                                                    className={classes.img_item}
                                                    onClick={() => handleOpen(msg.imageUrl || '', msg.content || '', msg.prompt || '')}
                                                >
                                                    <img
                                                        src={msg.imageUrl}
                                                        alt="Generated"
                                                        style={{
                                                            height: '200px',
                                                            width: '200px',
                                                        }}
                                                        loading="lazy"
                                                    />
                                                    {/* 다운로드 버튼 추가 */}

                                                </Box>
                                            )
                                    )}
                                </Box>
                            ) : (
                                <Box className={classes.gallery_wrap_noimg}>
                                    <Nostream
                                        title='No Photos Yet'
                                        text=' Start chatting now and generate vivid photos!'
                                        text2='' />;
                                </Box>
                            )
                            }

                        </Box>
                    )}
                </Box>
            )
            }

            {/* 모달 추가 */}
            <Modal open={modalOpen} onClose={handleClose}>
                <Box
                    className={classes.modalOverlay} // 추가된 클래스명
                    onClick={handleClose} // 모달 바깥을 클릭했을 때만 모달이 닫히도록 설정
                >
                    <Box
                        className={classes.modalContent} // 추가된 클래스명
                    >
                        <Box className={classes.image_wrap}>
                            <Box
                                className={classes.image_wrap2}
                                onClick={(e) => {
                                    e.preventDefault(); // 기본 동작 방지
                                    e.stopPropagation(); // 이벤트 전파 방지
                                }}
                            >
                                {selectedImage && (
                                    <img
                                        src={selectedImage}
                                        alt="Selected"
                                        loading="lazy"
                                        style={{
                                            width: 'auto', // 가로 크기를 자동으로 설정
                                            height: 'auto', // 세로 크기를 자동으로 설정
                                            maxWidth: '100%', // 부모 요소 너비 초과 방지
                                            maxHeight: '100%', // 부모 요소 높이 초과 방지
                                            objectFit: 'contain', // 이미지 비율 유지
                                        }}
                                    />
                                )}
                            </Box>
                        </Box>
                        <Box
                            className={classes.info_wrap}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleDownload(selectedImage || '')}
                                className={classes.downloadButton} // 추가된 클래스명
                            >
                                Download
                            </Button>
                            <Typography className={classes.modal_title}>Chat</Typography>
                            <Typography className={classes.modal_txt}>{selectedContent} </Typography>
                            <Typography className={classes.modal_title}>Prompt</Typography>
                            <Typography className={classes.modal_txt}>{selectedPrompt}</Typography>

                        </Box>
                    </Box>

                </Box>
            </Modal>

        </Box >
    );
};

export { Gallery };
