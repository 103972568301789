import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Typography, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

//recoil
import { ClientInfo, clientInfoAtom } from '../recoilAtoms';

//api
import { bubbleUrl } from '@services/api/baseapi';
import { apiCustomerinfoGetmeta } from '@services/api/customerinfo';
import { Logging } from '@services/api/hosterlog';

//icon
import { styles } from './subscribe.style';
import subs_check from '@common/assets/image/subs_check.svg';
import patreon_logo from '@common/assets/image/patreon_logo.svg';
import { apiPatreondisconnect, apiPatreonmeta, apiPatreonoauth } from '@services/api/patreon';
import { getStorage } from '@services/extension/funcs';

// others
import { DeleteModalPage } from './deletemodal';
import { Resultmodal } from './resultmodal';


const Subscribe: React.FC = () => {
  const classes = makeStyles(styles)();
  const [clientInfo, setClientInfo] = useRecoilState(clientInfoAtom);
  const [patreonEmail, setPatreonEmail] = useState("");

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const targetDateStr = clientInfo.paydue;
    if (targetDateStr) {
      const today = new Date();
      const targetDate = new Date(targetDateStr);
      const timeDifference: number = targetDate.getTime() - today.getTime();
      const daysLeft = Math.ceil(timeDifference / (1000 * 3600 * 24));
      setDuedate(` / ${daysLeft} days left`);
    }

  });

  const [duedate, setDuedate] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    const result = params.get('result');

    if (code) {
      const fetchPatreonConnect = async () => {
        const result_str = await apiPatreonoauth(code) || ""
        const fusio_sid = await getStorage(['fusio_sid']);
        const customerinfo = await apiCustomerinfoGetmeta(fusio_sid.fusio_sid || 'None');
        if (customerinfo) {
          const clientInfoResult: ClientInfo = {
            sid: clientInfo.sid,
            email: customerinfo.email,
            plan: customerinfo.plan,
            nsfw: customerinfo.nsfw,
            token: customerinfo.usagelimit,
            paymeta: customerinfo.paymeta,
            paydue: customerinfo.paydue,
          };
          setClientInfo(clientInfoResult);
        };
        setTimeout(() => setLoading(false), 1200);
        setTimeout(() => window.location.href = `${location.pathname}?result=${result_str}`, 1000);
      };
      fetchPatreonConnect();
    } else {
      setTimeout(() => setLoading(false), 500);
    }

    if (result) {
      setResultTxt(result)
      handleResultModalOpen()
    }


  }, []);

  useEffect(() => {
    interface PatreonMeta {
      email?: string;
    }

    interface Paymeta {
      patreon?: PatreonMeta | null;
    }

    let paymeta: Paymeta = {};
    try {
      if (clientInfo.paymeta) {
        paymeta = JSON.parse(clientInfo.paymeta);
      }
    } catch (error) {
      console.error("Error parsing paymeta:", error);
      paymeta = {};
    }

    setPatreonEmail(paymeta?.patreon?.email || "");
  }, [clientInfo]);

  const handleClinkBasicSubscribe = () => {
    Logging(clientInfo.sid, 'click_subscribe', 'basic', '')
    window.location.href = `${bubbleUrl}/store`
  };

  const handleClinkUltimateSubscribe = () => {
    Logging(clientInfo.sid, 'click_subscribe', 'ultimate', '')
    window.location.href = `${bubbleUrl}/store`
  };

  const handleClinkPatreonConnect = () => {
    const fetchPatreonConnect = async () => {
      const redirecturl = "https://fusioai.net/subscribe"
      const patreonid = await apiPatreonmeta('patreonid')
      const url = `https://www.patreon.com/oauth2/authorize?response_type=code&client_id=${patreonid}&redirect_uri=${redirecturl}&scope=identity identity[email]`
      window.location.href = url
      // await apiCustomerinfopatreonid(clientInfo.sid, 'testid');
    };
    fetchPatreonConnect();
  };

  const handleClinkPatreonDisconnect = () => {
    const fetchPatreonConnect = async () => {
      await apiPatreondisconnect()
      const fusio_sid = await getStorage(['fusio_sid']);
      const customerinfo = await apiCustomerinfoGetmeta(fusio_sid.fusio_sid || 'None');
      if (customerinfo) {
        const clientInfoResult: ClientInfo = {
          sid: clientInfo.sid,
          email: customerinfo.email,
          plan: customerinfo.plan,
          nsfw: customerinfo.nsfw,
          token: customerinfo.usagelimit,
          paymeta: customerinfo.paymeta,
          paydue: customerinfo.paydue,
        };
        setClientInfo(clientInfoResult);
      };
    };
    fetchPatreonConnect();
  };

  //delete modal
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleDeleteModalOpen = () => {
    setDeleteModalOpen(true)
  }

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false)
  }
  const handleDeleteModalDelete = async () => {
    handleClinkPatreonDisconnect()
    setDeleteModalOpen(false)
  };

  //result modal
  const [resultModalOpen, setResultModalOpen] = useState(false);
  const [resultTxt, setResultTxt] = useState("");
  const handleResultModalOpen = () => {
    setResultModalOpen(true)
  }

  const handleResultModalClose = () => {
    setResultModalOpen(false)
  }


  if (loading) {
    return (
      <Box className={classes.div_first_root}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress size={44} style={{ color: '#D84E98' }} />
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.div_first_root}>
      <DeleteModalPage
        modalOpen={deleteModalOpen}
        handleModalClose={handleDeleteModalClose}
        handleDeleteChat={handleDeleteModalDelete}
      />

      <Resultmodal
        resultmodalOpen={resultModalOpen}
        result={resultTxt}
        handleModalClose={handleResultModalClose}
      />

      <Box className={classes.div_title}>
        <Box className={classes.title_left}>
          <Typography className={classes.title_title}>Current Subscription </Typography>
          <Box className={classes.title_plan_wrap}>
            <Typography className={classes.title_state}>{clientInfo.plan}</Typography>
            {duedate && <Typography className={classes.title_due}>{duedate}</Typography>}
          </Box>
        </Box>

        {patreonEmail ? (
          <Box className={classes.title_right}>
            <Typography className={classes.title_right_text}>Connected Patreon Email : {patreonEmail}</Typography>
            <Button
              className={classes.title_patreon_button}
              onClick={() => handleDeleteModalOpen()}
              sx={{
                textTransform: 'none',
              }}
            >
              <img
                src={patreon_logo}
                style={{ maxHeight: '32px', maxWidth: '32px' }}
              />
              Disconnect Patreon
            </Button>
          </Box>
        ) : (
          <Box className={classes.title_right}>
            <Typography className={classes.title_right_text}>Are you a Patreon supporter?</Typography>
            <Button
              className={classes.title_patreon_button}
              onClick={() => handleClinkPatreonConnect()}
              sx={{
                textTransform: 'none',
              }}
            >
              <img
                src={patreon_logo}
                style={{ maxHeight: '32px', maxWidth: '32px' }}
              />
              Connect with Patreon
            </Button>
          </Box>
        )}


      </Box>
      <Box
        className={classes.div_first_wrap}
        onWheel={(e) => {
          e.currentTarget.scrollLeft += e.deltaY;
        }}
      >
        <Box
          className={clsx(classes.div_first, {
            [classes.div_first_pink]: clientInfo.plan === 'Free',
          })}
        >
          <Box className={classes.div_first_top}>
            <Box className={classes.div_first_title}>
              <Typography className={classes.title_first_title}>Free</Typography>
              <Typography className={classes.title_first_state}>$0/mo</Typography>
            </Box>
            <Box className={classes.info_line}>
              <img
                src={subs_check}
                alt="Selected"
                style={{ maxHeight: '32px', maxWidth: '32px' }}
              />
              <Typography className={classes.info}>15 tokens per day</Typography>
            </Box>
            <Box className={classes.info_line}>
              <img
                src={subs_check}
                alt="Selected"
                style={{ maxHeight: '32px', maxWidth: '32px' }}
              />
              <Typography className={classes.info}>Generate Uncensored Photos </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          className={clsx(classes.div_first, {
            [classes.div_first_pink]: clientInfo.plan === 'Basic',
          })}

        >
          <Box className={classes.div_first_top}>
            <Box className={classes.div_first_title}>
              <Typography className={classes.title_first_title}>Basic</Typography>
              <Typography className={classes.title_first_state}>$4.99/mo</Typography>
            </Box>
            <Box className={classes.info_line}>
              <img
                src={subs_check}
                alt="Selected"
                style={{ maxHeight: '32px', maxWidth: '32px' }}
              />
              <Typography className={classes.info}>1000 token per month</Typography>
            </Box>
            <Box className={classes.info_line}>
              <img
                src={subs_check}
                alt="Selected"
                style={{ maxHeight: '32px', maxWidth: '32px' }}
              />
              <Typography className={classes.info}>Generate Uncensored Photos </Typography>
            </Box>
          </Box>
          <Button
            className={clsx(classes.basic_button, {
              [classes.utilmate_button]: clientInfo.plan === 'Basic',
            })}
            onClick={() => handleClinkBasicSubscribe()}
            sx={{
              textTransform: 'none',
            }}
          >
            Subscribe
          </Button>
        </Box>
        <Box
          className={clsx(classes.div_first, {
            [classes.div_first_pink]: clientInfo.plan === 'Ultimate',
          })}

        >
          <Box className={classes.div_first_top}>
            <Box className={classes.div_first_title}>
              <Box className={classes.ultimate_title_wrap}>
                <Typography className={classes.title_first_title}>Ultimate</Typography>
                <Box className={classes.utlimate_desc}>
                  <Typography className={classes.utlimate_desc_txt}>Most populer</Typography>
                </Box>
              </Box>
              <Typography className={classes.title_first_state}>$14.99/mo</Typography>

            </Box>
            <Box className={classes.info_line}>
              <img
                src={subs_check}
                alt="Selected"
                style={{ maxHeight: '32px', maxWidth: '32px' }}
              />
              <Typography className={classes.info}>Unlimited token per month</Typography>
            </Box>
            <Box className={classes.info_line}>
              <img
                src={subs_check}
                alt="Selected"
                style={{ maxHeight: '32px', maxWidth: '32px' }}
              />
              <Typography className={classes.info}>Faster Image Generation </Typography>
            </Box>
            <Box className={classes.info_line}>
              <img
                src={subs_check}
                alt="Selected"
                style={{ maxHeight: '32px', maxWidth: '32px' }}
              />
              <Typography className={classes.info}>HD Image Generation </Typography>
            </Box>
            <Box className={classes.info_line}>
              <img
                src={subs_check}
                alt="Selected"
                style={{ maxHeight: '32px', maxWidth: '32px' }}
              />
              <Typography className={classes.info}>Generate Uncensored Photos  </Typography>
            </Box>
          </Box>
          <Button
            className={clsx(classes.basic_button, {
              [classes.utilmate_button]: clientInfo.plan === 'Ultimate',
            })}
            onClick={() => handleClinkUltimateSubscribe()}
            sx={{
              textTransform: 'none',
            }}
          >
            Subscribe
          </Button>
        </Box>
      </Box>
    </Box >
  );
};

export { Subscribe };
