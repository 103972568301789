import { createStyles } from '@mui/styles';

import { WessistTheme } from '@common/style/themeSelector';
import { maxWidth } from '@mui/system';

export const styles = (theme: WessistTheme) =>
  createStyles({
    div_message: {
      flex: 1, // Allow the container to grow and fill available space
      width: '100%',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      '&::-webkit-scrollbar': {
        width: '8px', // 스크롤바 너비
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888', // 스크롤바 색상
        borderRadius: '4px', // 스크롤바 둥글게
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#555', // 마우스 오버 시 색상 변경
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: theme.Wcolor.black, // 스크롤바 트랙 배경 색상
      },
    },
    message_init: {
      width: 'auto',
      maxWidth: '407px',
      height: '200px',
      margin: '24px auto 48px auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.Wcolor.white,
      flexShrink: 0,
    },
    message_init_top: {
      width: '100%',
      height: '111px',
      marginBottom: '24px',
      display: 'flex',
      flexDirection: 'row',
    },
    message_character_img: {
      width: '80px',
      height: '111px',
      margin: '0px 24px 0px 0px',
      display: 'flex',
      flexDirection: 'column',
      color: theme.Wcolor.white,
      flexShrink: 0,
      justifyContent: 'start',
      alignItems: 'center',
      // backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
    message_character_img_box: {
      height: '80px',
      width: '80px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
    message_character_info: {
      width: `calc(100% - 104px)`,
      height: '111px',
      display: 'flex',
      flexDirection: 'column',
      color: theme.Wcolor.white,
      flexShrink: 0,
      // backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
    message_character_info_txt: {
      fontSize: '15px',   // 글자 크기
      lineHeight: '1.2',  // 줄간격
      whiteSpace: 'pre-line'  // 줄바꿈 처리
    },
    message_init_bottom: {
      width: '100%',
      height: '112px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    message_bottom_desc: {
      width: '100%',
      fontSize: '14',
      color: theme.Wcolor.pink,
    },
    message_bottom_set: {
      width: '100%',
      height: '48px',
      border: '1px solid #D84E98',
      borderRadius: 8,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    div_header_set: {
      fontSize: '20px',
      color: theme.Wcolor.pink,
      whiteSpace: 'nowrap',
      // marginRight: '8px',
    },
    message_wrap: {
      maxWidth: '1300px',
      width: '100%',     // Allows the container to be responsive
      margin: '0px auto',  // Centers the container horizontally
      padding: '0px',
      display: 'flex',
      flexDirection: 'row',
      color: theme.Wcolor.white,
      // backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
    message_img_left: {
      height: '52px',
      width: '52px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      alignItems: 'center',
      marginRight: '24px',
    },
    message_main: {
      width: '100%',     // Allows the container to be responsive
      padding: '0px',
      display: 'flex',
      flexDirection: 'column',
      color: theme.Wcolor.white,
      fontSize: 16,
      fontWeight: '700',
      borderRadius: 8,
      flexShrink: 1,
    },
    message_content: {
      display: 'flex',
      flexDirection: 'column',
      color: theme.Wcolor.white,
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      borderRadius: 8,
      flexGrow: 1,  // 요소가 부모 요소의 폭에 맞춰 늘어날 수 있게 설정
      flexShrink: 1,  // 요소가 폭 줄어듦에 따라 줄어들 수 있게 설정
    },
    message_txt: {
      width: '100%',
      height: 'auto',
      borderRadius: '10px',
      padding: '10px',
      color: theme.Wcolor.white,
      fontSize: '15px',
      fontWeight: '600',
      whiteSpace: 'pre-line',
    },
    message_img_box: {
      padding: '5px',
      height: 'auto',
      display: 'flex',
      flexDirection: 'row',
      color: theme.Wcolor.white,
    },
    message_genbutton: {
      width: '100%',
      height: '35px',
      fontSize: '16px',
      backgroundColor: '#C35585',
      borderRadius: 8,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      textTransform: 'none',
    },
    message_genbutton_gen: {
      width: '100%',
      height: '35px',
      fontSize: '16px',
      backgroundColor: '#813648',
      borderRadius: 8,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      transform: 'None',
    },
  });
