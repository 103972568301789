// React 및 주요 라이브러리
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

// MUI 관련 컴포넌트와 스타일링
import { Box, Button, CircularProgress, Modal, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';


// 로컬 스타일 및 이미지 파일
import { styles } from './appearance.style';
import prevlight from '@common/assets/image/prevlight.svg';

// 리코일
import { chatInfoAtom, clientInfoAtom } from '../recoilAtoms';

// 설정 파일
import { config } from '@services/api/baseapi';
import clsx from 'clsx';

// api
import { apiChatlistAppearance, apiChatlistFixedprompt } from '@services/api/chatlist';
import { fetchChatlistItem, fetchCustomer } from './apiServices';

type ApperaanceProps = {
  appOpen: boolean;
  handleAppClose: () => void;
  chatid: string;
  chatIdHistory: string;
};

const Apperaance: React.FC<ApperaanceProps> = ({
  appOpen,
  handleAppClose,
  chatid,
  chatIdHistory,
}) => {
  const classes = makeStyles(styles)();
  const [clientInfo, setClientInfo] = useRecoilState(clientInfoAtom);
  const [chatInfo, setChatInfo] = useRecoilState(chatInfoAtom);

  const [loading, setLoading] = useState(true); // 첫 랜딩 로딩써클

  const sex_title = 'Sex'
  const sex_option = ['Male', 'Female', 'Femboy', 'None']
  const [sexSelected, setSexSelected] = useState('None');

  const special_title = 'Special'
  const special_option = ['Furry', 'Femdom','Pony', 'Fantasy', 'Cyberpunk', 'Gay','None']
  const [specialSelected, setSpecialSelected] = useState('None');

  const age_title = 'Age'
  const age_option = ['20s', '30s', '40s', '50s','60+', 'None']
  const [ageSelected, setAgeSelected] = useState('None');

  const ethnicity_title = 'Ethnicity'
  const ethnicity_option = ['African', 'White', 'Black', 'Arabic', 'South_asian', 'East_asian', 'Indian', 'Biracial', 'Native_american', 'None']
  const [ethnicitySelected, setEthnicitySelected] = useState('None');

  const body_title = 'Body'
  const body_option = ['Skinny', 'Muscular', 'Plump', 'Curvy',  'Fat', 'Giantess', 'None']
  const [bodySelected, setBodySelected] = useState('None');

  const face_title1 = 'Face1'
  const face_option1 = ['Round_face', 'Sharp_face', 'Eyeliner', 'Eyeshadow', 'Lipstick', 'Lipglose', 'Facepaint', 'Thin_lips', 'Tanned_skin','Tanlines','None']
  const [faceSelected1, setFaceSelected1] = useState('None');

  const face_title2 = 'Face2'
  const face_option2 = ['Round_face', 'Sharp_face', 'Eyeliner', 'Eyeshadow', 'Lipstick', 'Lipglose', 'Facepaint', 'Thin_lips', 'Tanned_skin','Tanlines','None']
  const [faceSelected2, setFaceSelected2] = useState('None');

  const hairstyle_title = 'Hair Style'
  const hairstyle_option = ['Short_hair', 'Bald', 'Long_hair', 'Wavy_hair', 'Curly_hair', 'Cornrows', 'Braid', 'Dreadlocks', 'Pixie_cut', 'Ponytail', 'Twintails', 'Hair_bun', 'Hime_cut', 'Messy_hair', 'Buzz_cut', 'Undercut','None']
  const [hairstyleSelected, setHairstyleSelected] = useState('None');

  const haircolor_title = 'Hair Color'
  const haircolor_option = ['Black_hair', 'Blonde_hair', 'Red_hair', 'White_hair', 'Pink_hair','Brown_hair','Green_hair','Purple_hair','Blue_hair','Grey_hair','None']
  const [haircolorSelected, setHaircolorSelected] = useState('None');

  const breast_title = 'Breast'
  const breast_option = ['Flat_chest', 'Small_chest', 'Medium_chest', 'Large_chest', 'Huge_chest', 'Gigantic_chest','None']
  const [breastSelected, setBreastSelected] = useState('None');

  const ass_title = 'Ass'
  const ass_option = ['Flat_ass', 'Spread_ass', 'Medium_ass', 'Large_ass', 'Huge_ass', 'Gigantic_ass','None']
  const [assSelected, setAssSelected] = useState('None');


  const [fixedPrompt, setFixedPrompt] = useState('');
  const handleFixedPrompt = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFixedPrompt(event.target.value); // 입력값에 따라 modalPrompt 업데이트
  };

  const fetchFixedprompt = async () => {
    const customerChatId = await fetchCustomer(clientInfo.sid, chatIdHistory);
    const chatInfoData = await fetchChatlistItem(clientInfo.sid, customerChatId);
    setChatInfo(chatInfoData);
    setFixedPrompt(chatInfoData.fixedprompt)
    if (chatInfoData.appearance !== "") {
      const parsedLlmres: Record<string, string> = JSON.parse(chatInfoData.appearance);
      setSexSelected(parsedLlmres["sex"] || "");
      setAgeSelected(parsedLlmres["age"] || "");
      setSpecialSelected(parsedLlmres["special"] || "");
      setEthnicitySelected(parsedLlmres["ethnicity"] || "");
      setBodySelected(parsedLlmres["body"] || "");
      setFaceSelected1(parsedLlmres["face1"] || "");
      setFaceSelected2(parsedLlmres["face2"] || "");
      setHairstyleSelected(parsedLlmres["hairstyle"] || "");
      setHaircolorSelected(parsedLlmres["haircolor"] || "");
      setBreastSelected(parsedLlmres["breast"] || "");
      setAssSelected(parsedLlmres["ass"] || "");
    }
  };

  const saveFixedprompt = async () => {
    await apiChatlistFixedprompt(clientInfo.sid, chatid, fixedPrompt);
    const appTot = `{"sex": "${sexSelected}", "age": "${ageSelected}", "special": "${specialSelected}", "ethnicity": "${ethnicitySelected}", "body": "${bodySelected}", "face1": "${faceSelected1}", "face2": "${faceSelected2}", "hairstyle": "${hairstyleSelected}", "haircolor": "${haircolorSelected}", "breast": "${breastSelected}", "ass": "${assSelected}"}`
    await apiChatlistAppearance(clientInfo.sid, chatid, appTot);
  };

  useEffect(() => {
    fetchFixedprompt(); // checked가 변경될 때만 실행
    setTimeout(() => setLoading(false), 500);
  }, []);
  
  if (loading) {
    return (
      <Box className={classes.root}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress size={44} style={{ color: '#D84E98' }} />
        </Box>
      </Box>
    );
  }
  
  return (
    <Box className={classes.root}>
      <Box className={classes.prev_box} onClick={handleAppClose}>
          <img
            src={prevlight}
            alt="Selected"
            className={classes.modalImage}
            style={{ maxHeight: '32px', maxWidth: '32px' }}

          />
      </Box>
      <Box className={classes.scroll_root_overflow}>
      <Box className={classes.scroll_root}>

        <Box className={classes.character}>
          <Box className={classes.character_child_img}>
            <img
              src={`${config.baseurl}/api/images/${chatInfo.img}`}
              style={{ height: '120px', width: '120px' }}
            />
          </Box>
          <Box className={classes.character_child}>
            <Typography className={classes.character_child_name}>{chatInfo.charactername}</Typography>
            <Typography className={classes.character_child_domain}>, {chatInfo.domain}</Typography>
          </Box>
        </Box>
        <Box className={classes.textfield_wrap}>
          <Typography className={classes.fixedprompt}>Fixed Prompt</Typography>
          <Typography className={classes.fixedprompt_det}><span style={{ fontWeight: 'bold', color: '#D84E98' }}>Fixed Prompt</span> is a prompt that applies to all image generation. Use when the desired image doesn't generate as expected</Typography>
          <TextField
            className={classes.textfield}
            value={fixedPrompt} // 기본값으로
            onChange={handleFixedPrompt} // 입력값 변경 시 호출되는 함수
            multiline // 여러 줄 입력 허용
            placeholder='femdom, '
            variant="outlined" // 테두리 스타일 설정
            InputProps={{
              style: {
                border: 'none', // 테두리 없애기
                color: 'white', // 텍스트 색상
                fontSize: '15px', // 폰트 크기
                overflowWrap: 'break-word', // 단어 줄 바꿈
                backgroundColor: 'transparent', // 배경 투명
              },
            }}
            InputLabelProps={{
              style: {
                color: 'white', // 레이블 색상
              },
            }}
          />
        </Box>
        <Box className={classes.option_wrap}>
          <Typography className={classes.option_title}>{sex_title}</Typography>
          <Box className={classes.option_but_wrap}>
            {sex_option.map((option) => (
              <Button
                key={option}
                className={clsx(classes.option_button, {
                  [classes.option_button_get]: option === sexSelected,
                })}
                onClick={() => setSexSelected(option)}
                sx={{
                  textTransform: 'none',
                }}
              >
                {option}
              </Button>
            ))}
          </Box>
        </Box>

        <Box className={classes.option_wrap}>
          <Typography className={classes.option_title}>{special_title}</Typography>
          <Box className={classes.option_but_wrap}>
            {special_option.map((option) => (
              <Button
                key={option}
                className={clsx(classes.option_button, {
                  [classes.option_button_get]: option === specialSelected,
                })}
                onClick={() => setSpecialSelected(option)}
                sx={{
                  textTransform: 'none',
                }}
              >
                {option}
              </Button>
            ))}
          </Box>
        </Box>

        <Box className={classes.option_wrap}>
          <Typography className={classes.option_title}>{age_title}</Typography>
          <Box className={classes.option_but_wrap}>
            {age_option.map((option) => (
              <Button
                key={option}
                className={clsx(classes.option_button, {
                  [classes.option_button_get]: option === ageSelected,
                })}
                onClick={() => setAgeSelected(option)}
                sx={{
                  textTransform: 'none',
                }}
              >
                {option}
              </Button>
            ))}
          </Box>
        </Box>

        <Box className={classes.option_wrap}>
          <Typography className={classes.option_title}>{ethnicity_title}</Typography>
          <Box className={classes.option_but_wrap}>
            {ethnicity_option.map((option) => (
              <Button
                key={option}
                className={clsx(classes.option_button, {
                  [classes.option_button_get]: option === ethnicitySelected,
                })}
                onClick={() => setEthnicitySelected(option)}
                sx={{
                  textTransform: 'none',
                }}
              >
                {option}
              </Button>
            ))}
          </Box>
        </Box>

        <Box className={classes.option_wrap}>
          <Typography className={classes.option_title}>{body_title}</Typography>
          <Box className={classes.option_but_wrap}>
            {body_option.map((option) => (
              <Button
                key={option}
                className={clsx(classes.option_button, {
                  [classes.option_button_get]: option === bodySelected,
                })}
                onClick={() => setBodySelected(option)}
                sx={{
                  textTransform: 'none',
                }}
              >
                {option}
              </Button>
            ))}
          </Box>
        </Box>

        <Box className={classes.option_wrap}>
          <Typography className={classes.option_title}>{face_title1}</Typography>
          <Box className={classes.option_but_wrap}>
            {face_option1.map((option) => (
              <Button
                key={option}
                className={clsx(classes.option_button, {
                  [classes.option_button_get]: option === faceSelected1,
                })}
                onClick={() => setFaceSelected1(option)}
                sx={{
                  textTransform: 'none',
                }}
              >
                {option}
              </Button>
            ))}
          </Box>
        </Box>

        <Box className={classes.option_wrap}>
          <Typography className={classes.option_title}>{face_title2}</Typography>
          <Box className={classes.option_but_wrap}>
            {face_option2.map((option) => (
              <Button
                key={option}
                className={clsx(classes.option_button, {
                  [classes.option_button_get]: option === faceSelected2,
                })}
                onClick={() => setFaceSelected2(option)}
                sx={{
                  textTransform: 'none',
                }}
              >
                {option}
              </Button>
            ))}
          </Box>
        </Box>

        <Box className={classes.option_wrap}>
          <Typography className={classes.option_title}>{hairstyle_title}</Typography>
          <Box className={classes.option_but_wrap}>
            {hairstyle_option.map((option) => (
              <Button
                key={option}
                className={clsx(classes.option_button, {
                  [classes.option_button_get]: option === hairstyleSelected,
                })}
                onClick={() => setHairstyleSelected(option)}
                sx={{
                  textTransform: 'none',
                }}
              >
                {option}
              </Button>
            ))}
          </Box>
        </Box>

        <Box className={classes.option_wrap}>
          <Typography className={classes.option_title}>{haircolor_title}</Typography>
          <Box className={classes.option_but_wrap}>
            {haircolor_option.map((option) => (
              <Button
                key={option}
                className={clsx(classes.option_button, {
                  [classes.option_button_get]: option === haircolorSelected,
                })}
                onClick={() => setHaircolorSelected(option)}
                sx={{
                  textTransform: 'none',
                }}
              >
                {option}
              </Button>
            ))}
          </Box>
        </Box>



        <Box className={classes.option_wrap}>
          <Typography className={classes.option_title}>{breast_title}</Typography>
          <Box className={classes.option_but_wrap}>
            {breast_option.map((option) => (
              <Button
                key={option}
                className={clsx(classes.option_button, {
                  [classes.option_button_get]: option === breastSelected,
                })}
                onClick={() => setBreastSelected(option)}
                sx={{
                  textTransform: 'none',
                }}
              >
                {option}
              </Button>
            ))}
          </Box>
        </Box>

        <Box className={classes.option_wrap}>
          <Typography className={classes.option_title}>{ass_title}</Typography>
          <Box className={classes.option_but_wrap}>
            {ass_option.map((option) => (
              <Button
                key={option}
                className={clsx(classes.option_button, {
                  [classes.option_button_get]: option === assSelected,
                })}
                onClick={() => setAssSelected(option)}
                sx={{
                  textTransform: 'none',
                }}
              >
                {option}
              </Button>
            ))}
          </Box>
        </Box>


      </Box>
      </Box>
      <Button
        onClick={() => saveFixedprompt()}
        className={classes.genbutton}
        sx={{
          textTransform: 'none',
        }}
      >
        Save a Character
      </Button>
    </Box>
  );
};

export { Apperaance };
