import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { clientInfoAtom, ClientInfo } from '../recoilAtoms';

import {
    apiCustomerinfoActivate,
    apiCustomerinfoGetmeta,
} from '@services/api/customerinfo';

import { isChromeExtension, getStorage } from '@services/extension/funcs';
import { PatreonModal } from './patreonmodal';
import { apiPatreonmeta } from '@services/api/patreon';

const addStorageListener = (callback: (sid: string) => void) => {
    if (isChromeExtension()) {
        chrome.storage.onChanged.addListener((changes, namespace) => {
            if (namespace === 'local' && changes.fusio_sid) {
                callback(changes.fusio_sid.newValue || 'None');
            }
        });
    } else {
        const handleStorageChange = (event: StorageEvent) => {
            if (event.key === 'fusio_sid' && event.newValue) {
                callback(event.newValue);
            }
        };
        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }
};

const Daemon: React.FC = () => {
    const navigate = useNavigate();
    const [clientInfo, setClientInfo] = useRecoilState(clientInfoAtom);
    const [sid, setSidState] = useState<string>('None');
    const [isSidNone, setIsSidNone] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);


    //delete modal
    const [patreonModalOpen, setPatreonModalOpen] = useState(false);
    const handleTokenModalClose = () => {
        const fetchCustomerInfo = async () => {
            await apiCustomerinfoActivate(clientInfo.sid, 1);
        };
        fetchCustomerInfo();
        setPatreonModalOpen(false);
    };

    const handleClinkPatreonConnect = () => {
        const fetchPatreonConnect = async () => {
            const redirecturl = "https://fusioai.net/subscribe"
            const patreonid = await apiPatreonmeta('patreonid')
            const url = `https://www.patreon.com/oauth2/authorize?response_type=code&client_id=${patreonid}&redirect_uri=${redirecturl}&scope=identity identity[email]`
            window.location.href = url
            // await apiCustomerinfopatreonid(clientInfo.sid, 'testid');
        };
        fetchPatreonConnect();
    };

    useEffect(() => {
        const initializeSid = async () => {
            const result = await getStorage(['fusio_sid']);
            const currentSid = result.fusio_sid || 'None';
            setSidState(currentSid);

        };
        initializeSid();

        setTimeout(() => {
            setIsInitialized(true);
        }, 100);

        const removeListener = addStorageListener((newSid) => {
            setSidState(newSid);
        });

        return () => {
            if (removeListener) removeListener();
        };
    }, []);

    useEffect(() => {
        if (!isInitialized) return;

        if (sid === 'None') {
            setIsSidNone(true)
            navigate('/login');
        } else {
            const fetchCustomerInfo = async () => {
                const customerinfo = await apiCustomerinfoGetmeta(sid);
                if (customerinfo) {
                    const clientInfoResult: ClientInfo = {
                        sid,
                        email: customerinfo.email,
                        plan: customerinfo.plan,
                        nsfw: customerinfo.nsfw,
                        token: customerinfo.usagelimit,
                        paymeta: customerinfo.paymeta,
                        paydue: customerinfo.paydue,
                    };
                    setClientInfo(clientInfoResult);
                    if (customerinfo.activate === 2) {
                        setPatreonModalOpen(true)
                    };
                }
            };

            fetchCustomerInfo();
            if (isSidNone) {
                setIsSidNone(false)
                navigate('/');
            }
        }
    }, [sid, isInitialized]);

    if (patreonModalOpen) {
        return <PatreonModal
            patreonModalOpen={patreonModalOpen}
            handleModalClose={handleTokenModalClose}
            handleClinkPatreon={handleClinkPatreonConnect}
        />
    }

    return <div />;
};

export { Daemon };

