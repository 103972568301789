import { createStyles } from '@mui/styles';

import { WessistTheme } from '@common/style/themeSelector';

export const styles = (theme: WessistTheme) =>
  createStyles({
    root: {
      height: `calc(100% - 0px)`,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      // borderLeft: '1px solid #ddd',
    },
    root_main: {
      height: `calc(100% - 0px)`,
      width: '100%',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    chatid_list: {
      height: `calc(100% - 0px)`,
      // width: '200px',
      overflowY: 'auto',
      display: 'flex',
      padding: '10px 0px 0px 0px',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      gap: '10px',
      // borderRight: '1px solid #ddd',
      '&::-webkit-scrollbar': {
        width: '8px', // 스크롤바 너비
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888', // 스크롤바 색상
        borderRadius: '4px', // 스크롤바 둥글게
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#555', // 마우스 오버 시 색상 변경
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: theme.Wcolor.black, // 스크롤바 트랙 배경 색상
      },
    },
    chat_list_item_box: {
      height: '30px',
    },
    chat_list_item: {
      height: '30px',
      marginBottom: '10px',
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      textTransform: 'none',
      color: theme.Wcolor.white,
      padding: '0px 0px 0px 10px',
    },
    buttonSelected: {
      backgroundColor: 'rgba(216, 78, 152, 0.2)',
      color: theme.Wcolor.pink,
    },
    gallery_main_circular: {
      height: '100%',
      width: `calc(100% - 200px)`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    gallery_main: {
      height: '100%',  // 원하는 높이 계산식
      // width: `calc(100% - 200px)`,
      overflowY: 'auto',  // 가로로는 overflow 발생하지 않게 설정
      display: 'flex',
      flexWrap: 'wrap',     // 줄바꿈되도록 설정
      flexDirection: 'row',
      padding: '10px',      // padding 간격 조정
      justifyContent: 'flex-start',  // 가로 정렬을 시작점에서부터 하도록 설정
      alignItems: 'start',      // 세로 정렬을 시작점에서부터 하도록 설정
      '&::-webkit-scrollbar': {
        width: '8px', // 스크롤바 너비
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888', // 스크롤바 색상
        borderRadius: '4px', // 스크롤바 둥글게
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#555', // 마우스 오버 시 색상 변경
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: theme.Wcolor.black, // 스크롤바 트랙 배경 색상
      },
    },
    gallery_wrap: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',     // 줄바꿈되도록 설정
      flexDirection: 'row',
    },
    img_item: {
      height: '200px',
      width: '200px',
      margin: '10px',
      // border: '1px solid #ddd',
    },
    gallery_wrap_noimg: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',     // 줄바꿈되도록 설정
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    gallery_wrap_noimg_text: {
      fontSize: '40px',
      fontWeight: '900',
      color: theme.Wcolor.white,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    modalOverlay: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
    modalContent: {
      width: '100%',
      height: '100%',
      maxHeight: '90vh',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
    },
    image_wrap: {
      width: 'auto',
      height: '100%',
      maxHeight: '784px',
      maxWidth: '784px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    image_wrap2: {
      width: 'auto',
      height: '100%',
      maxHeight: '784px',
      maxWidth: '784px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    info_wrap: {
      width: '400px',
      height: '100%',
      maxHeight: '784px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'center',
      overflowY: 'auto',    // 세로로는 스크롤이 가능하도록 설정
      '&::-webkit-scrollbar': {
        width: '8px', // 스크롤바 너비
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888', // 스크롤바 색상
        borderRadius: '4px', // 스크롤바 둥글게
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#555', // 마우스 오버 시 색상 변경
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: theme.Wcolor.black, // 스크롤바 트랙 배경 색상
      },
    },
    downloadButton: {
      maxWidth: '150px',
      height: '25px',
      fontSize: '16px',
      color: theme.Wcolor.white,
      backgroundColor: theme.Wcolor.pink,
      borderRadius: '4px',
      marginBottom: '10px',
    },
    modal_title: {
      fontSize: '20px',
      lineHeight: '1.0',
      color: theme.Wcolor.white,
    },
    modal_txt: {
      fontSize: '16px',
      fontWeight: '100',
      lineHeight: '1.0',
      color: theme.Wcolor.white,
    },
  });
