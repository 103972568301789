import { createStyles } from '@mui/styles';

import { WessistTheme } from '@common/style/themeSelector';

export const styles = (theme: WessistTheme) =>
  createStyles({
    modalOverlay: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
    modalContent1: {
      width: '100%',
      maxWidth: '529px',
      height: '380px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'center',
      borderRadius: 8,
      backgroundColor: theme.Wcolor.black,
    },
    modalContent2: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'start',
      padding: '28px 48px',
    },
    title_title: {
      fontSize: '32px',
      fontWeight: '700',
      color: theme.Wcolor.white,
      marginBottom: '10px',
    },
    title_first_title: {
      fontSize: '28px',
      fontWeight: '700',
      color: theme.Wcolor.white,
    },
    title_first_state: {
      fontSize: '28px',
      fontWeight: '700',
      color: theme.Wcolor.pink2,
    },
    info_line: {
      width: '100%',
      height: '24px',
      fontSize: '20px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      alignItems: 'center',
      color: theme.Wcolor.white,
      margin: '12px 0px 0px 0px',
    },
    info: {
      fontSize: '16px',
      color: theme.Wcolor.white,
      lineHeight: '1.1',
      margin: '0px 0px 0px 12px',
    },
    basic_button: {
      width: '100%',
      height: '50px',
      fontSize: '24px',
      fontWeight: '700',
      color: theme.Wcolor.black,
      backgroundColor: theme.Wcolor.pink2,
      borderRadius: '10px',
      margin: '24px 0px 0px 0px',
    },
  });
