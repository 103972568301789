import React, { useMemo, useState } from 'react';
import { Box, Button, Tab, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { styles } from './intro.style';

import { DoubleMHeader } from './header/header';
import { Chatlist } from './chatlist/chatlist';
import { Chatstream } from './chatstream/chatstream';
import { Gallery } from './gallery/gallery';
import { Profile } from './profile/profile';
import { Test } from './test/test';
import { Admin } from './admin/admin';
import { Navbar } from './navbar/navbar';
import { Subscribe } from './subscribe/subscribe';
import { Daemon } from './daemon/daemon';
import { Login } from './login/login';
import { Feedback } from './feedback/feedback';
import { Share } from './share/share';
import { Sharelist } from './sharelist/sharelist';


const ChatStream: React.FC = () => {
  const classes = makeStyles(styles)();

  return (
    <Box className={classes.root}>
      <Daemon />
      <Helmet>
        <title>FusioAI</title>
      </Helmet>
      <DoubleMHeader />
      <Box className={classes.body}>
        <Navbar />
        <Box sx={{ flexGrow: 1, height: '100%', width: '10px' }}>
          <Chatstream chatIdHistory="" />
        </Box>
      </Box>
    </Box>
  );
};


const SharePage: React.FC = () => {
  const classes = makeStyles(styles)();

  return (
    <Box className={classes.root}>
      <Helmet>
        <title>FusioAI</title>
      </Helmet>
      <DoubleMHeader />
      <Box className={classes.body}>
        <Navbar />
        <Box sx={{ flexGrow: 1, height: '100%', width: '10px' }}>
          <Share />
        </Box>
      </Box>
    </Box>
  );
};

const ChatList: React.FC = () => {
  const classes = makeStyles(styles)();

  return (
    <Box className={classes.root}>
      <Daemon />
      <Helmet>
        <title>FusioAI</title>
      </Helmet>
      <DoubleMHeader />
      <Box className={classes.body}>
        <Navbar />
        <Box sx={{ flexGrow: 1, height: '100%', width: '10px' }}>
          <Chatlist />
        </Box>
      </Box>
    </Box>
  );
};

const SharelistPage: React.FC = () => {
  const classes = makeStyles(styles)();

  return (
    <Box className={classes.root}>
      <Helmet>
        <title>FusioAI</title>
      </Helmet>
      <DoubleMHeader />
      <Box className={classes.body}>
        <Navbar />
        <Box sx={{ flexGrow: 1, height: '100%', width: '10px' }}>
          <Sharelist />
        </Box>
      </Box>
    </Box>
  );
};


const ChatUI: React.FC = () => {
  const classes = makeStyles(styles)();
  const params = new URLSearchParams(location.search);
  const chatIdHistory = params.get('chatid') || "";

  return (
    <Box className={classes.root}>
      <Daemon />
      <Helmet>
        <title>FusioAI</title>
      </Helmet>
      <DoubleMHeader />
      <Box className={classes.body}>
        <Navbar />
        <Box sx={{ flexGrow: 1, height: '100%', width: '10px' }}>
          <Chatstream chatIdHistory={chatIdHistory} />
        </Box>
      </Box>
    </Box>
  );
};

const GalleryPage: React.FC = () => {
  const classes = makeStyles(styles)();

  return (
    <Box className={classes.root}>
      <Daemon />
      <Helmet>
        <title>FusioAI</title>
      </Helmet>
      <DoubleMHeader />
      <Box className={classes.body}>
        <Navbar />
        <Box sx={{ flexGrow: 1, height: '100%', width: '10px' }}>
          <Gallery />
        </Box>
      </Box>
    </Box>
  );
};


const ProfilePage: React.FC = () => {
  const classes = makeStyles(styles)();

  return (
    <Box className={classes.root}>
      <Daemon />
      <Helmet>
        <title>FusioAI</title>
      </Helmet>
      <DoubleMHeader />
      <Box className={classes.body}>
        <Navbar />
        <Box sx={{ flexGrow: 1, height: '100%', width: '10px' }}>
          <Profile />
        </Box>
      </Box>
    </Box>
  );
};

const FeedbackPage: React.FC = () => {
  const classes = makeStyles(styles)();

  return (
    <Box className={classes.root}>
      <Daemon />
      <Helmet>
        <title>FusioAI</title>
      </Helmet>
      <DoubleMHeader />
      <Box className={classes.body}>
        <Navbar />
        <Box sx={{ flexGrow: 1, height: '100%', width: '10px' }}>
          <Feedback />
        </Box>
      </Box>
    </Box>
  );
};

const SubscribePage: React.FC = () => {
  const classes = makeStyles(styles)();

  return (
    <Box className={classes.root}>
      <Daemon />
      <Helmet>
        <title>FusioAI</title>
      </Helmet>
      <DoubleMHeader />
      <Box className={classes.body}>
        <Navbar />
        <Box sx={{ flexGrow: 1, height: '100%', width: '10px' }}>
          <Subscribe />
        </Box>
      </Box>
    </Box>
  );
};

const LoginPage: React.FC = () => {
  const classes = makeStyles(styles)();

  return (
    <Box className={classes.root}>
      <Daemon />
      <Helmet>
        <title>FusioAI</title>
      </Helmet>
      <Login />
    </Box>
  );
};

const TestPage: React.FC = () => {
  const classes = makeStyles(styles)();

  return (
    <Box className={classes.root}>
      <Daemon />
      <Helmet>
        <title>FusioAI</title>
      </Helmet>
      <DoubleMHeader />
      <Box className={classes.body}>
        <Navbar />
        <Box sx={{ flexGrow: 1, height: '100%', width: '10px' }}>
          <Test />
        </Box>
      </Box>
    </Box>
  );
};


const AdminPage: React.FC = () => {
  const classes = makeStyles(styles)();

  return (
    <Box className={classes.root}>
      <Daemon />
      <Helmet>
        <title>FusioAI</title>
      </Helmet>
      <DoubleMHeader />
      <Box className={classes.body}>
        <Navbar />
        <Box sx={{ flexGrow: 1, height: '100%', width: '10px' }}>
          <Admin />
        </Box>
      </Box>
    </Box>
  );
};


export {
  ChatStream,
  SharePage,
  ChatList,
  SharelistPage,
  ChatUI,
  GalleryPage,
  TestPage,
  AdminPage,
  ProfilePage,
  SubscribePage,
  LoginPage,
  FeedbackPage,
};
