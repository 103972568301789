// React 및 주요 라이브러리
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

// MUI 관련 컴포넌트와 스타일링
import { Box, Button, Modal, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ChatInfo, chatInfoAtom, clientInfoAtom } from '../recoilAtoms';


// 로컬 스타일 및 이미지 파일
import { styles } from './modal.style';
import modal_fixed_pin from '@common/assets/image/modal_fixed_pin.svg';
import modal_fixed_info from '@common/assets/image/modal_fixed_info.svg';


type ModalPageProps = {
  modalOpen: boolean;
  handleModalClose: () => void;
  modalSelectedImage: string | null;
  modalPrompt: string;
  modalLlmres: string;
  handleModalChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  chatInfo: ChatInfo;
};

const ModalPage: React.FC<ModalPageProps> = ({
  modalOpen,
  handleModalClose,
  modalSelectedImage,
  modalPrompt,
  modalLlmres,
  handleModalChange,
  chatInfo,
}) => {
  const classes = makeStyles(styles)();
  const [checked, setChecked] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const [fixedPrompt, setFixedPrompt] = useState('');
  const handleMFixedPromptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  };

  const npart_title = 'Number of Participants'
  const [npartContent, setNpartContent] = useState('');
  const handleSetNpartContent = (event: React.ChangeEvent<HTMLInputElement>) => {
  };

  const posture_title = 'Posture'
  const [postureContent, setPostureContent] = useState('');
  const handleSetPostureContent = (event: React.ChangeEvent<HTMLInputElement>) => {
  };

  const facial_title = 'Facial Expression'
  const [facialContent, setFacialContent] = useState('');
  const handleSetFacialContent = (event: React.ChangeEvent<HTMLInputElement>) => {
  };

  const props_title = 'Props'
  const [propContent, setPropsContent] = useState('');
  const handleSetPropsContent = (event: React.ChangeEvent<HTMLInputElement>) => {
  };

  const location_title = 'Location'
  const [locationContent, setLocationContent] = useState('');
  const handleSetLocationContent = (event: React.ChangeEvent<HTMLInputElement>) => {
  };

  const nsfw_title = 'NSFW'
  const [nsfwContent, setNSFWContent] = useState('');
  const handleSetNSFWContent = (event: React.ChangeEvent<HTMLInputElement>) => {
  };

  const etc_title = 'Etc'
  const [etcContent, setEtcContent] = useState('');
  const handleSetEtcContent = (event: React.ChangeEvent<HTMLInputElement>) => {
  };


  const handleClickGenButton = () => {
  }

  useEffect(() => {
    if (modalLlmres !== "") {
      const parsedLlmres: Record<string, string> = JSON.parse(modalLlmres);
      setNpartContent(parsedLlmres["task1"] || "");
      setNSFWContent(parsedLlmres["task2"] || "");
      setPostureContent(parsedLlmres["task3"] || "");
      setFacialContent(parsedLlmres["task4"] || "");
      setLocationContent(parsedLlmres["task5"] || "");
      setPropsContent(parsedLlmres["task6"] || "");
      setEtcContent(parsedLlmres["task7"] || "");
      setFixedPrompt(chatInfo.fixedprompt)
    }
  }, [modalLlmres]);


  const saveFixedprompt = async () => {
  };

  return (

    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      disablePortal // Portal을 비활성화해서 렌더링 충돌을 줄임
      disableEnforceFocus // 포커스 강제 이동을 비활성화
    >
      <Box
        className={classes.modalOverlay}
        onClick={handleModalClose} // 모달 바깥을 클릭했을 때만 모달이 닫히도록 설정
      >
        <Box
          className={classes.modalContent1}
          onClick={(e) => e.stopPropagation()} // 모달 내부 클릭 시 닫히지 않도록 설정
        >
          <Box
            className={classes.modalContent2}
            onClick={(e) => e.stopPropagation()} // 모달 내부 클릭 시 닫히지 않도록 설정
          >
            <Box className={classes.modalImageBox}>
              {modalSelectedImage && (
                <img
                  src={modalSelectedImage}
                  alt="Selected"
                  className={classes.modalImage}
                  loading="lazy"
                />
              )}
            </Box>
            <Box className={classes.toggleBox}>
              <Typography className={classes.toggleTitle}>Edit Full Prompt</Typography>
              <Switch
                className={classes.toggleButton}
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleClickGenButton()}
              className={classes.genbutton}
              sx={{
                textTransform: 'none',
              }}
            >
              Generate
            </Button>
            <Box className={classes.modalSidebar}>
              {checked ? (
                // <Box className={classes.modalTextFieldWrap}>
                <TextField
                  className={classes.modalTextField}
                  value={modalPrompt} // 기본값으로
                  onChange={handleModalChange} // 입력값 변경 시 호출되는 함수
                  multiline // 여러 줄 입력 허용
                  InputProps={{
                    style: {
                      color: 'white', // 입력 텍스트 색상
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: 'white', // 레이블 색상
                    },
                  }}
                />
                // </Box>
              ) : (
                <Box className={classes.partwrap}>
                  <Box className={classes.fixedwrap}>
                    <Box className={classes.fixedwrapfirst}>
                      <img
                        src={modal_fixed_pin}
                        style={{
                          height: '20px',
                          width: '20px',
                        }}
                      />
                      <Typography className={classes.fixedfirsttext}>Fixed Prompt</Typography>
                    </Box>
                    <Tooltip
                      title="Fixed Prompt is permanent prompt that’s always applied when describing this character. It’s a great tool to use when you’re not getting the results you want. Using short, clear keywords works best"
                      slotProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: '#2D2D2D', // 배경색
                            color: '#fff', // 글씨 색
                            fontSize: '14px', // 글씨 크기
                            padding: '10px 16px', // 내부 여백
                            borderRadius: '4px', // 둥근 모서리
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)', // 그림자 효과
                          },
                        },
                      }}
                    >
                      <Box className={classes.fixedwrapfirst}>
                        <img
                          src={modal_fixed_info}
                          style={{
                            height: '20px',
                            width: '20px',
                          }}
                        />
                        <Typography className={classes.fixedsecondtext}>What is Fixed Prompt</Typography>
                      </Box>
                    </Tooltip>
                  </Box>
                  <Box className={classes.fixedboxwrap}>
                    <TextField
                      className={classes.fixedboxtextfield}
                      value={fixedPrompt} // 기본값으로
                      onChange={handleMFixedPromptChange} // 입력값 변경 시 호출되는 함수
                      multiline // 여러 줄 입력 허용
                      variant="outlined" // 테두리 스타일 설정
                      InputLabelProps={{
                        style: {
                          color: 'white', // 레이블 색상
                        },
                      }}
                    />
                    <Button
                      onClick={() => saveFixedprompt()}
                      className={classes.fixedboxgenbutton}
                      sx={{
                        textTransform: 'none',
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                  <Box className={classes.textfieldblock}>
                    <Typography className={classes.textfieldblocktitle}>{npart_title}</Typography>
                    <TextField
                      className={classes.textfieldblockfield}
                      value={npartContent}
                      onChange={handleSetNpartContent}
                      multiline
                      variant="outlined"
                      InputLabelProps={{
                        style: {
                          color: 'white',
                        },
                      }}
                    />
                  </Box>
                  <Box className={classes.textfieldblock}>
                    <Typography className={classes.textfieldblocktitle}>{posture_title}</Typography>
                    <TextField
                      className={classes.textfieldblockfield}
                      value={postureContent}
                      onChange={handleSetPostureContent}
                      multiline
                      variant="outlined"
                      InputLabelProps={{
                        style: {
                          color: 'white',
                        },
                      }}
                    />
                  </Box>
                  <Box className={classes.textfieldblock}>
                    <Typography className={classes.textfieldblocktitle}>{facial_title}</Typography>
                    <TextField
                      className={classes.textfieldblockfield}
                      value={facialContent}
                      onChange={handleSetFacialContent}
                      multiline
                      variant="outlined"
                      InputLabelProps={{
                        style: {
                          color: 'white',
                        },
                      }}
                    />
                  </Box>
                  <Box className={classes.textfieldblock}>
                    <Typography className={classes.textfieldblocktitle}>{location_title}</Typography>
                    <TextField
                      className={classes.textfieldblockfield}
                      value={locationContent}
                      onChange={handleSetLocationContent}
                      multiline
                      variant="outlined"
                      InputLabelProps={{
                        style: {
                          color: 'white',
                        },
                      }}
                    />
                  </Box>
                  <Box className={classes.textfieldblock}>
                    <Typography className={classes.textfieldblocktitle}>{props_title}</Typography>
                    <TextField
                      className={classes.textfieldblockfield}
                      value={propContent}
                      onChange={handleSetPropsContent}
                      multiline
                      variant="outlined"
                      InputLabelProps={{
                        style: {
                          color: 'white',
                        },
                      }}
                    />
                  </Box>
                  <Box className={classes.textfieldblock}>
                    <Typography className={classes.textfieldblocktitle}>{nsfw_title}</Typography>
                    <TextField
                      className={classes.textfieldblockfield}
                      value={nsfwContent}
                      onChange={handleSetNSFWContent}
                      multiline
                      variant="outlined"
                      InputLabelProps={{
                        style: {
                          color: 'white',
                        },
                      }}
                    />
                  </Box>
                  <Box className={classes.textfieldblock}>
                    <Typography className={classes.textfieldblocktitle}>{etc_title}</Typography>
                    <TextField
                      className={classes.textfieldblockfield}
                      value={etcContent}
                      onChange={handleSetEtcContent}
                      multiline
                      variant="outlined"
                      InputLabelProps={{
                        style: {
                          color: 'white',
                        },
                      }}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export { ModalPage };
