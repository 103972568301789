import { createAxiosInstance, config } from './baseapi';


interface GenImgInterface {
  sid: string;
  chatid: string;
  idx: number;
  passwd: string;
  text: string;
  msgid: string;
  isprompt: boolean;
}

interface GenImgResponse {
  image_url: string; // 서버에서 이미지 URL을 반환한다는 가정
}

async function apiGenImage(sid: string, chatid: string, idx: number, text: string, msgid: string, isprompt: boolean): Promise<string | null> {
  const data: GenImgInterface = {
    sid: sid,
    chatid: chatid,
    idx: idx,
    passwd: 'boldsignal!',
    text: text,
    msgid: msgid,
    isprompt: isprompt,
  };

  try {
    const axiosInstance = await createAxiosInstance();
    const response = await axiosInstance.post<GenImgResponse>('/api/gen-img', data);
    return response.data.image_url;
  } catch (error) {
    console.error('Error during POST request:', error);
    return null;
  }
}

export { apiGenImage };
