import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styles } from './navbar.style';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { pageAtom, navbarCollapsed } from '../recoilAtoms';
import nav_leftarraw from '@common/assets/image/nav_leftarraw.svg';
import nav_hamburger from '@common/assets/image/nav_hamburger.svg';
import nav_person from '@common/assets/image/nav_person.svg';

const Navbar: React.FC = () => {
    // 사이드바 상태 (축소 여부)
    const [isCollapsed, setIsCollapsed] = useRecoilState(navbarCollapsed);
    const [showButtons, setShowButtons] = useState(true); // 버튼 표시 상태
    const classes = makeStyles(styles)();
    const [page, setPage] = useRecoilState(pageAtom);
    const navigate = useNavigate();

    const handleSetSelectedPage = (dest: string, collapse: boolean) => {
        navigate(dest);
        setPage(dest);
    };

    const handleBackbutton = () => {
        setIsCollapsed(!isCollapsed);
    };

    useEffect(() => {
        if (!isCollapsed && !showButtons) {
            const timer = setTimeout(() => {
                setShowButtons(true);
            }, 800);

            return () => clearTimeout(timer);
        }
        if (isCollapsed) {
            setShowButtons(false);
        }
    }, [isCollapsed]);


    const handleAppOpen = () => {
        navigate('/profile');
    }

    const handleFeedback = () => {
        navigate('/feedback');
    };

    return (
        <Box
            className={clsx(classes.root, { [classes.rootrightborder]: isCollapsed === false })}
            sx={{
                width: isCollapsed ? '0px' : '240px',
                transition: 'width 0.7s ease',
            }}
        >
            <Box
                className={classes.back_button}
                onClick={handleBackbutton}
            >
                {isCollapsed ? (
                    <img
                        src={nav_hamburger}
                        style={{ width: '25px', height: '25px' }}
                    />
                ) : (
                    <img
                        src={nav_leftarraw}
                        style={{ width: '25px', height: '25px' }}
                    />
                )}

            </Box>
            {!isCollapsed && showButtons && (
                <>
                    <Box className={classes.root_div}>
                        <Box className={classes.main_div}>
                            <Button
                                className={clsx(classes.button, {
                                    [classes.buttonSelected]: page === '/streaming',
                                })}
                                onClick={() => handleSetSelectedPage('/streaming', true)}
                            >
                                Streaming
                            </Button>
                            <Button
                                className={clsx(classes.button, {
                                    [classes.buttonSelected]: page === '/chatlist',
                                })}
                                onClick={() => handleSetSelectedPage('/chatlist', true)}
                            >
                                Characters
                            </Button>
                            <Button
                                className={clsx(classes.button, {
                                    [classes.buttonSelected]: page === '/gallery',
                                })}
                                onClick={() => handleSetSelectedPage('/gallery', false)}
                            >
                                Gallery
                            </Button>
                        </Box>
                        <Box className={classes.second_div}>
                            <Button
                                className={clsx(classes.button, {
                                    [classes.buttonSelected]: page === '/indev',
                                })}
                                onClick={() => handleSetSelectedPage('/indev', true)}
                            >
                                Image Generation
                            </Button>
                        </Box>
                    </Box>
                    <Box className={classes.bottom_div} >
                        <Box className={classes.bottom_feedback_div} onClick={() => handleFeedback()} >
                            <Typography className={classes.bottom_feedback_txt}>
                                Feedback
                            </Typography>
                        </Box>
                        <Box className={classes.bottom_profile_div} onClick={() => handleAppOpen()} >
                            <img src={nav_person}
                                style={{ width: '28px', height: '28px' }}
                                onClick={() => handleAppOpen()}
                            />
                            <Typography className={classes.bottom_profile_txt}>
                                Profile
                            </Typography>
                        </Box>
                    </Box>
                </>
            )
            }
        </Box >
    );
};

export { Navbar };