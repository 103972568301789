// React 및 주요 라이브러리
import React from 'react';

// MUI 관련 컴포넌트와 스타일링
import { Box, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

// 전역 상태 관리 (Recoil Atoms)
import {
  ChatImage,
} from '../recoilAtoms';


// 로컬 스타일 및 이미지 파일
import { styles } from './imagebox.style';
import stream_reload from '@common/assets/image/stream_reload.svg';
import stream_chat from '@common/assets/image/stream_chat.svg';
import stream_down from '@common/assets/image/stream_down.svg';
import stream_delete from '@common/assets/image/stream_delete.svg';


interface ChatstreamProps {
  divImageHeight: number
  images: ChatImage[]
  handleDownload: (imageUrl: string) => Promise<void>
  handleModalOpen: (imageUrl: string, prompt: string, llmres: string, idx: number) => void
}

const ImageBox: React.FC<ChatstreamProps> = ({
  divImageHeight,
  images,
  handleDownload,
  handleModalOpen,
}) => {
  const classes = makeStyles(styles)();

  const handleDelete = async (msgid: string) => {
  };

  return (
    <Box className={classes.div_root}
      style={{
        width: '100%',
        height: `calc(${divImageHeight}px - 35px)`,
      }}
      onWheel={(e) => {
        e.currentTarget.scrollLeft += e.deltaY; // 세로 스크롤을 가로 스크롤로 변환
      }}>

      {images.map(
        (msg, index) =>
          <Box
            key={index} className={classes.div_image_item_wrap}
            style={{
              width: `calc(${divImageHeight}px - 55px + 28px)`,
              height: `calc(${divImageHeight}px - 55px)`,
            }}>
            <Box
              className={classes.div_image_img}
              style={{
                width: `calc(${divImageHeight}px - 55px)`,
                height: `calc(${divImageHeight}px - 55px)`,
              }}>
              {msg.imageUrl ? (
                <img
                  src={msg.imageUrl}
                  alt="Generated"
                  style={{
                    width: `calc(${divImageHeight}px - 55px)`,
                    height: `calc(${divImageHeight}px - 55px)`,
                  }}
                  loading="lazy"
                />
              ) : (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                  <CircularProgress size={32} style={{ color: '#D84E98' }} />
                </Box>
              )}
            </Box>
            <Box className={classes.div_image_but}>
              <Box
                className={classes.div_image_wrap}
                onClick={() => {
                  handleDelete(msg.msgid || '');
                }}>
                <img
                  src={stream_delete}
                  style={{ height: '20px', width: '20px', marginBottom: '8px' }} loading="lazy"
                />
              </Box>
              <Box
                className={classes.div_image_wrap}>
                <img
                  src={stream_reload}
                  style={{ height: '20px', width: '20px', marginBottom: '8px' }} loading="lazy"
                />
              </Box>
              <Box
                className={classes.div_image_wrap}
                onClick={() => {
                  handleDownload(msg.imageUrl || '');
                }}>
                <img
                  src={stream_down}
                  style={{ height: '20px', width: '20px', marginBottom: '8px' }} loading="lazy"
                />
              </Box>
              <Box
                className={classes.div_image_wrap}
                onClick={() => {
                  handleModalOpen(msg.imageUrl || '', msg.prompt || "", msg.llmres || "", msg.idx);
                }}>
                <img
                  src={stream_chat}
                  style={{ height: '20px', width: '20px', marginBottom: '8px' }} loading="lazy"
                />
              </Box>
            </Box>
          </Box>
      )
      }
    </Box>

  );
};

export { ImageBox };
