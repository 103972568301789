import { createStyles } from '@mui/styles';

import { WessistTheme } from '@common/style/themeSelector';

export const styles = (theme: WessistTheme) =>
  createStyles({
    div_first_root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'center',
      padding: '40px 0 0 0',
    },
    div_first: {
      width: '800px',
      height: '128px',
      marginBottom: '80px',
    },
    div_first_title: {
      fontSize: '40px',
      fontWeight: '900',
      color: theme.Wcolor.white,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    div_first_msg: {
      fontSize: '28px',
      fontWeight: '300',
      color: theme.Wcolor.white,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      lineHeight: '1.1',
      textAlign: 'center',
    },
    div_second: {
      width: '800px',
      height: '108px',
    },
    second_msg: {
      fontSize: '20px',
      fontWeight: '700',
      color: theme.Wcolor.white,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    second_column: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    second_item: {
      width: '280px',
      height: '237px',
      margin: '12px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
    },
    second_img_item: {
      width: '280px',
      height: '200px',
    },
    second_name: {
      width: '280px',
      height: '29px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    second_img_link: {
      width: '28px',
      height: '28px',
      marginRight: '8px',
    },
    second_img_name: {
      fontSize: '24px',
      color: theme.Wcolor.white,
    },
  });
