import { createStyles } from '@mui/styles';

import { WessistTheme } from '@common/style/themeSelector';

export const styles = (theme: WessistTheme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'start',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      padding: '5px 0px 0px 0px',
    },
    scroll_root_overflow: {
      display: 'flex',
      justifyContent: 'start',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: '8px', // 스크롤바 너비
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888', // 스크롤바 색상
        borderRadius: '4px', // 스크롤바 둥글게
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#555', // 마우스 오버 시 색상 변경
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: theme.Wcolor.black, // 스크롤바 트랙 배경 색상
      },
    },
    scroll_root: {
      display: 'flex',
      justifyContent: 'start',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      maxWidth: '1000px',
      height: '100%',
    },
    prev_box: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      width: '100%',
      maxWidth: '1000px',
      padding: '0px 35px',
      height: '30px',
    },
    character: {
      width: '100%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '24px'
    },
    character_child_img: {
      width: '120px',
      height: '120px',
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '8px',
    },
    character_child: {
      display: 'flex',
      flexDirection: 'row',
    },
    character_child_name: {
      fontSize: '20px',
      fontWeight: '900',
      color: theme.Wcolor.white,
    },
    character_child_domain: {
      fontSize: '20px',
      // fontWeight: '900',
      color: theme.Wcolor.white,
      whiteSpace: 'nowrap',
    },
    textfield_wrap: {
      padding: '0px 44px',
      width: '100%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'start',
      marginTop: '24px'
    },
    textfield: {
      width: '100%',
      minHeight: '120px',
      color: theme.Wcolor.white,
      border: '1px solid #D84E98',
      borderRadius: 8,
      backgroundColor: 'transparent', // 배경 투명
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          border: 'none', // 기본 테두리 제거
        },
        '&:hover fieldset': {
          border: 'none', // 호버 시에도 테두리 제거
        },
        '&.Mui-focused fieldset': {
          border: 'none', // 포커스 시에도 테두리 제거
        },
      },
    },
    fixedprompt: {
      fontSize: '22px',
      color: theme.Wcolor.pink,
    },
    fixedprompt_det: {
      fontSize: '16px',
      color: theme.Wcolor.white,
    },

    option_wrap: {
      padding: '0px 44px',
      width: '100%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'start',
      marginTop: '10px'
    },
    option_title: {
      fontSize: '20px',
      color: theme.Wcolor.white,
      // marginBottom: '4px',
    },
    option_but_wrap: {
      padding: '0px 0px',
      width: '100%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      alignItems: 'center',
      flexWrap: 'wrap', // 줄 바꿈 설정
      gap: '4px',
    },
    option_button: {
      padding: '0px 20px',
      width: 'auto',
      height: '46px',
      fontSize: '18px',
      backgroundColor: '#252525',
      border: '1px solid #8d8d8d',
      borderRadius: 8,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.Wcolor.white,
      // marginRight: '4px',
    },
    option_button_get: {
      width: 'auto',
      height: '46px',
      fontSize: '18px',
      backgroundColor: '#C35585',
      border: '1px solid #B92F72',
      borderRadius: 8,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.Wcolor.white,
      // marginRight: '8px',
    },
    modalImage: {
      // width: '50%',
      // height: '50%',
      maxHeight: '90%',
      maxWidth: '90%',
      objectFit: 'contain',
      // border: '2px solid white',
    },
    genbuttonparent: {
      position: 'fixed', // 화면에 고정
      bottom: '16px', // 화면 하단에서 16px 띄움
      left: '50%', // 화면의 중앙으로 이동
      transform: 'translateX(-50%)', // 정렬 보정
      width: '100%', // 부모의 전체 너비 사용
      display: 'flex',
      justifyContent: 'center', // 중앙 정렬
      zIndex: 1000, // 다른 요소 위에 표시
    },
    genbutton: {
      width: 'calc(100% - 10px)', // 화면 좌우 여백을 제외한 너비
      maxWidth: '1000px',
      height: '35px',
      backgroundColor: theme.Wcolor.pink2,
      borderRadius: 4,
      margin: '0px 5px 5px 5px',
      color: theme.Wcolor.white,
    },
  });
