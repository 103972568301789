import { createStyles } from '@mui/styles';

import { WessistTheme } from '@common/style/themeSelector';

export const styles = (theme: WessistTheme) =>
  createStyles({
    modalOverlay: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
    modalContent1: {
      width: '400px',
      height: '90%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'center',
      borderRadius: 8,
      backgroundColor: theme.Wcolor.black,
    },
    modalContent2: {
      width: '360px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'center',
    },
    modalImageBox: {
      width: '100%',
      height: '350px',
      objectFit: 'contain',
      marginTop: '16px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      // border: '2px solid white',
    },
    modalImage: {
      width: '350px',
      height: '350px',
      objectFit: 'contain',
    },
    toggleBox: {
      width: '100%',
      height: '24px',
      objectFit: 'contain',
      marginTop: '16px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'end',
      alignItems: 'center',
      // border: '2px solid white',
    },
    toggleTitle: {
      fontSize: '14px',
      color: theme.Wcolor.white,
    },
    toggleButton: {
      width: '60px',
      color: theme.Wcolor.white,
      marginRight: '16px',
      '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
          color: '#fff',
          '& + .MuiSwitch-track': {
            backgroundColor: '#D84E98',
            opacity: 1,
          },
        },
      },
      '& .MuiSwitch-track': {
        backgroundColor: '#8D8D8D',
      },
    },
    genbutton: {
      marginTop: '16px',
      width: '366px',
      height: '35px',
      backgroundColor: theme.Wcolor.pink2,
      borderRadius: 4,
    },
    modalSidebar: {
      marginTop: '16px',
      width: '100%',
      height: `calc(100% - 433px)`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'start',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: '4px', // 스크롤바 너비
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888', // 스크롤바 색상
        borderRadius: '4px', // 스크롤바 둥글게
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#555', // 마우스 오버 시 색상 변경
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: theme.Wcolor.black, // 스크롤바 트랙 배경 색상
      },
    },
    modalTextFieldWrap: {
      width: '100%',
      height: '100%',
    },
    modalTextField: {
      width: '100%',
      height: `calc(100% - 16px)`,
      color: theme.Wcolor.white,
      border: '1px solid white',
      borderRadius: 4,
      backgroundColor: 'transparent', // 배경 투명
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          border: 'none', // 기본 테두리 제거
        },
        '&:hover fieldset': {
          border: 'none', // 호버 시에도 테두리 제거
        },
        '&.Mui-focused fieldset': {
          border: 'none', // 포커스 시에도 테두리 제거
        },
        '& input': {
          color: 'white', // 입력 필드의 텍스트 색상
        },
      },
    },
    partwrap: {
      width: `calc(100% - 5px)`,
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'center',
      paddingBottom: '5px',
      // border: '1px solid white',
    },
    fixedwrap: {
      width: '100%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    fixedwrapfirst: {
      width: 'auto',
      height: '20px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    fixedfirsttext: {
      fontSize: '14px',
      color: theme.Wcolor.pink,
    },
    tooltip: {
      fontSize: '16px',
      backgroundColor: theme.Wcolor.gray2,
    },
    fixedsecondtext: {
      fontSize: '14px',
      color: theme.Wcolor.gray2,
      fontWeight: '100',
      textDecoration: 'underline',
    },
    fixedboxwrap: {
      marginTop: '3px',
      width: '100%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    fixedboxtextfield: {
      width: `calc(100% - 90px)`,
      minHeight: '34px',
      color: theme.Wcolor.white,
      border: '1px solid #D84E98',
      borderRadius: 4,
      backgroundColor: 'transparent', // 배경 투명
      '& .MuiOutlinedInput-root': {
        padding: 0, // 전체 패딩 제거
        '& fieldset': {
          border: 'none', // 기본 테두리 제거
        },
      },
      '& .MuiInputBase-root': {
        padding: '8px', // 텍스트 주변 패딩
        color: 'white',
        fontSize: '14px',
        lineHeight: '1.1', // 텍스트 줄 간격
        alignItems: 'center',
      },
      '& .MuiInputBase-input': {
        padding: 0, // 추가 패딩 제거
        backgroundColor: 'transparent',
        fontSize: '14px',
        lineHeight: '1.1', // 줄 간격
        overflowWrap: 'break-word', // 단어 줄바꿈
      },
    },
    fixedboxgenbutton: {
      width: '83px',
      height: '34px',
      backgroundColor: theme.Wcolor.pink2,
      borderRadius: 4,
      color: theme.Wcolor.white,
    },
    textfieldblock: {
      marginTop: '5px',
      width: '100%',
      height: 'auto',
    },
    textfieldblocktitle: {
      fontSize: '16px',
      color: theme.Wcolor.white,
    },
    textfieldblockfield: {
      width: `calc(100%)`,
      minHeight: '34px',
      color: theme.Wcolor.white,
      border: '1px solid #ffffff',
      borderRadius: 4,
      backgroundColor: 'transparent', // 배경 투명
      '& .MuiOutlinedInput-root': {
        padding: 0, // 전체 패딩 제거
        '& fieldset': {
          border: 'none', // 기본 테두리 제거
        },
      },
      '& .MuiInputBase-root': {
        padding: '8px', // 텍스트 주변 패딩
        color: 'white',
        fontSize: '14px',
        lineHeight: '1.1', // 텍스트 줄 간격
        alignItems: 'center',
      },
      '& .MuiInputBase-input': {
        padding: 0, // 추가 패딩 제거
        backgroundColor: 'transparent',
        fontSize: '14px',
        lineHeight: '1.1', // 줄 간격
        overflowWrap: 'break-word', // 단어 줄바꿈
      },
    },
  });
