import { createStyles } from '@mui/styles';

import { WessistTheme } from '@common/style/themeSelector';

export const styles = (theme: WessistTheme) =>
  createStyles({
    root: {
      margin: 0,
      width: '100%',
      height: '100%',
      padding: '0px',
      backgroundColor: theme.Wcolor.black,
      overflow: 'hidden',
    },
    body: {
      margin: 0,
      width: '100%',
      height: `calc(100% - 72px)`,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      alignItems: 'center',
      overflow: 'hidden',
    },
  });
