// React 및 주요 라이브러리
import React, { useState } from 'react';

// MUI 관련 컴포넌트와 스타일링
import { Box, Button, Modal, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

// 로컬 스타일 및 이미지 파일
import { styles } from './sorrymodal.style';


type sorrymodalPageProps = {
  modalOpen: boolean;
  handleModalClose: () => void;
};

const SorrymodalPage: React.FC<sorrymodalPageProps> = ({
  modalOpen,
  handleModalClose,
}) => {
  const classes = makeStyles(styles)();

  return (

    <Modal open={modalOpen} onClose={handleModalClose}>
      <Box
        className={classes.modalOverlay}
        onClick={handleModalClose} // 모달 바깥을 클릭했을 때만 모달이 닫히도록 설정
      >
        <Box
          className={classes.modalContent}
          onClick={(e) => e.stopPropagation()} // 모달 내부 클릭 시 닫히지 않도록 설정
        >
          <Typography className={classes.modalTitle}>Sorry ...</Typography>
          <Typography className={classes.modalText}>Server trafic is too high. Please try again.</Typography>
          <Box className={classes.buttonBox}>
            <Button
              className={classes.modalDelButton}
              onClick={() => handleModalClose()}
              sx={{
                textTransform: 'none',
              }}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export { SorrymodalPage };
