import React from 'react';

import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { styles } from './nostream.style';

import icon_link from '@common/assets/image/icon_link.svg'
import domain_spicy from '@common/assets/image/domain_spicy.svg'
import domain_janitor from '@common/assets/image/domain_janitor.svg'
import domain_character from '@common/assets/image/domain_character.svg'
import domain_chrush from '@common/assets/image/domain_chrush.svg'

type NostreamProps = {
  title: string;
  text: string;
  text2: string;
};

const Nostream: React.FC<NostreamProps> = ({ title, text, text2 }) => {
  const classes = makeStyles(styles)();
  return (
    <Box className={classes.div_first_root}>
      <Box className={classes.div_first}>
        <Typography className={classes.div_first_title}>{title}</Typography>
        <Typography className={classes.div_first_msg}>{text}</Typography>
        <Typography className={classes.div_first_msg}>{text2}</Typography>
      </Box>
      <Box className={classes.div_second}>
        <Typography className={classes.second_msg}>Fusio is perfectly integrated with </Typography>
        <Box className={classes.second_column}>
          <Box className={classes.second_item}>
            <img src={domain_character} className={classes.second_img_item} />
            <Box
              component="a"
              href="https://character.ai/"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.second_name}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                textDecoration: 'none',
              }}
            >
              <img src={icon_link} className={classes.second_img_link} />
              <Typography className={classes.second_img_name}>CharacterAI</Typography>
            </Box>
          </Box>
          <Box className={classes.second_item}>
            <img src={domain_janitor} className={classes.second_img_item} />
            <Box
              component="a"
              href="https://janitorai.com/"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.second_name}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                textDecoration: 'none',
              }}
            >
              <img src={icon_link} className={classes.second_img_link} />
              <Typography className={classes.second_img_name}>JanitorAI</Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.second_column}>
          <Box className={classes.second_item}>
            <img src={domain_spicy} className={classes.second_img_item} />
            <Box
              component="a"
              href="https://spicychat.ai/"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.second_name}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                textDecoration: 'none',
              }}
            >
              <img src={icon_link} className={classes.second_img_link} />
              <Typography className={classes.second_img_name}>SpicyChatAI</Typography>
            </Box>
          </Box>
          <Box className={classes.second_item}>
            <img src={domain_chrush} className={classes.second_img_item} />
            <Box
              component="a"
              href="https://crushon.ai/"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.second_name}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                textDecoration: 'none',
              }}
            >
              <img src={icon_link} className={classes.second_img_link} />
              <Typography className={classes.second_img_name}>CrushOnAI</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { Nostream };
