import { atom } from 'recoil';

export type ChatMessage = {
    text: string;
    idx: number;
    imageUrl?: string;
    ischaracter: boolean;
};

export type ChatImage = {
    id: number;
    content: string;
    prompt: string;
    llmres: string;
    idx: number;
    imageUrl?: string;
    msgid: string;
};


export type ChatInfo = {
    chatid: string;
    img: string;
    domain: string;
    charactername: string;
    sdmodel: string;
    fixedprompt: string;
    appearance: string;
};


export type ClientInfo = {
    sid: string;
    email: string;
    plan: string;
    nsfw: boolean;
    token: number;
    paymeta: string;
    paydue: string;
};


export const navbarCollapsed = atom<boolean>({
    key: 'navbarcollapsed',
    default: false,
});


export const pageAtom = atom<string>({
    key: 'page',
    default: '',
});


export const clientInfoAtom = atom<ClientInfo>({
    key: 'clientInfo',
    default: {
        sid: 'none',
        email: 'none',
        plan: 'none',
        nsfw: false,
        token: 0,
        paymeta: "",
        paydue: "",
    },
});


export const pageHeight = atom<number>({
    key: 'pageheight',
    default: 300,
});


export const chatInfoAtom = atom<ChatInfo>({
    key: 'chatInfoInfo',
    default: {
        chatid: 'none',
        img: 'none',
        domain: 'none',
        charactername: 'none',
        sdmodel: 'none',
        fixedprompt: '',
        appearance: ''
    },
});


export const sdmodelAtom = atom<string>({
    key: 'sdmodel',
    default: 'none',
});


export const isAppOpen = atom<boolean>({
    key: 'isAppOpen',
    default: false,
});
